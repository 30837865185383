import React from "react";
import Topics from "../../components/topics/Topics";
import { colours, TT } from "../../consts.js";

const TopicSentimentChart = ({ topicdata }) => {
  // Define capcase locally
  const capcase = (input) => {
    if (!input || typeof input !== "string") return "";
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  };

  
  return (

    <div>
      
    
    <div className="vocalysd-card">
    <div className="vocalysd-heading">
        Topic based sentiment
        <TT
          info={
            "Topics are words or phrases that come up frequently across Conversations, and carry a Sentiment."
          }
        />
      </div>

      

      <Topics
        topichits={topicdata}
        capcase={capcase}
        title="Topic Based Sentiment"
        maxItems={15}
        sortKey="z"
        filterKey="keyword"
        color={colours.green100}
      />
    </div>
    </div>
  );
};

export default TopicSentimentChart;
