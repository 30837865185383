import React, { createContext, useEffect, useState } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsconfig from "../../aws-exports";

// Ensure correct configuration for OAuth
awsconfig.oauth = {
    domain: "userpass.vocalysd.ai",
    scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
    ],
    responseType: "code",
    redirectSignIn: process.env.NODE_ENV === "development"
        ? "http://localhost:3000/"
        : "https://vault.vocalysd.ai/",
    redirectSignOut: process.env.NODE_ENV === "development"
        ? "http://localhost:3000/"
        : "https://vault.vocalysd.ai/"
};

// Initialize Amplify
Amplify.configure(awsconfig);

// AuthContext Definition
export const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        user: null,
        isAuthenticated: false,
        isAdmin: false,
    });

    // Function to check the current authenticated user
    const checkUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const groups = user.signInUserSession.accessToken.payload["cognito:groups"] || [];
            setAuthState({
                user,
                isAuthenticated: true,
                isAdmin: groups.includes("admin"),
            });
        } catch {
            setAuthState({
                user: null,
                isAuthenticated: false,
                isAdmin: false,
            });
        }
    };

    // Function to get the JWT token
    const getJwtToken = () => {
        if (authState.isAuthenticated && authState.user) {
            return authState.user.signInUserSession.idToken.jwtToken;
        }
        throw new Error("User is not authenticated");
    };

    useEffect(() => {
        // Check user on load
        checkUser();

        // Listen for authentication events
        const listener = Hub.listen("auth", ({ payload: { event } }) => {
            switch (event) {
                case "signIn":
                case "cognitoHostedUI":
                    checkUser();
                    break;
                case "signOut":
                    setAuthState({
                        user: null,
                        isAuthenticated: false,
                        isAdmin: false,
                    });
                    break;
                default:
                    break;
            }
        });

        // Cleanup listener
        return () => listener();
    }, []);

    return (
        <AuthContext.Provider value={{ ...authState, getJwtToken }}>
            {children}
        </AuthContext.Provider>
    );
};
