import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { colours } from "../../consts.js";
import FeatherIcon from "feather-icons-react";
import { useNavigate, useLocation } from "react-router-dom";
import Utils from "../../utils";
import CircularProgress from "@mui/material/CircularProgress";

function CampaignArchived() {
  let navigate = useNavigate();
  let location = useLocation();
  let camid = location.search.replace("?id=", "");
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [campaignID] = React.useState(camid);
  // const [campaignName, setCampaignName] = React.useState(camid);
  const [data, setData] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);
  async function getCampaignData() {
    Utils.sendProgressBar(true);
    setSpinner(true);
    let camids = location.search.replace("?id=", "");
    
    let list = []
    let s = await Utils.genericListMOD(
      "listConversations",
      {
        filter: {
          campaignConversationsId: { eq: camids },
          archive: { eq: 'true' },
        },
        //limit:5000
      },
      true
    );
    list = list.concat(s.data.listConversations.items)
    setData(list);
    while (s.data.listConversations.nextToken !== null) {
      s = await Utils.genericListMOD(
        "listConversations",
        {
          filter: {
            campaignConversationsId: { eq: camids },
            archive: { eq: 'true' },
          },

          nextToken:s.data.listConversations.nextToken
        },
        true
      );
      list = list.concat(s.data.listConversations.items)
      setData(list);
    }

    setSpinner(false);

  }
  React.useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        await getCampaignData(); 
        setDataLoaded(true); 
      };
      fetchData();
    }

    window.scrollTo(0, 0); 

  }, [dataLoaded]);

  return (
    <div className="vocalysed-home">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          background: "#f8f9fd",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: 30,
              }}
            ></div>
          </div>
          <div  className="vocalysd-card vocalysd-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      File
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Agent
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Call date
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Avg. Talk Duration
                    </div>
                  </TableCell>

                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Avg. Vocalysd score
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      # of Alert hits
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {spinner ? (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <CircularProgress
                        style={{
                          color: colours.primary100,
                          width: 20,
                          height: 20,
                        }}
                      />
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ) : (
                  data.map((z, zidx) => (
                    <TableRow
                      key={zidx}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/conversation?id=" + z.id)}
                    >

                      <TableCell>
                        <div
                          style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                        >
                          {z.file}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontFamily: "Poppins" }}>{z.agent}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontFamily: "Poppins" }}>
                          {z.startdate.substr(0,10)}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontFamily: "Poppins" }}>
                          {z.duration
                            ? Math.floor(z.duration / 60) +
                              "min " +
                              (z.duration % 60).toFixed(0) +
                              "sec"
                            : ""}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontFamily: "Poppins" }}>
                          {z.symblstatus === "completed" ? (
                            (z.VS || 0) + "%"
                          ) : (
                            <CircularProgress
                              style={{
                                color: colours.primary100,
                                width: 20,
                                height: 20,
                              }}
                            />
                          )}
                        </div>
                      </TableCell>

                      <TableCell>
                        {z.alertcount > 0 ? (
                          <div
                            style={{
                              color: colours.orange100,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {z.alertcount}
                            <FeatherIcon
                              icon="alert-triangle"
                              size="20"
                              style={{ marginLeft: 5 }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              color: colours.black,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            -
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignArchived;
