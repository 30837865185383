import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import FeatherIcon from "feather-icons-react";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#999" : "#999",
  },
}));

const BorderLinearProgressP = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#4caf50" : "#4caf50",
  },
}));

const Topics = ({ 
  topichits, 
  capcase, 
  color,  
  maxItems, 
  sortKey = "n", 
  filterKey = "topic" 
}) => {
  if (!topichits || topichits.length === 0) {
    return null;
  }

  const filteredData = topichits
    .filter((item) => item[filterKey]) // Dynamic filter based on `filterKey`
    .sort((a, b) => b[sortKey] - a[sortKey]) // Sort by `sortKey`
    .slice(0, maxItems); // Limit items based on `maxItems`

  return (
    <div>

      <div>
        {filteredData.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                textAlign: "right",
                marginRight: 10,
                marginBottom: 5,
                fontSize: 12,
              }}
            >
              {capcase(item[filterKey])}
            </div>
            <Box
              sx={{
                color: color.subduedgray,
                width: "65%",
              }}
            >
              {(item.s === "pos" || item.sen === "pos" ) ? (
                <BorderLinearProgressP
                  variant="determinate"
                  color="inherit"
                  className="bar-positive"
                  value={Math.min(item[sortKey], 100)}
                />
              ) : (item.s === "neg" || item.sen === "neg" ) ? (
                <BorderLinearProgressP
                  variant="determinate"
                  color="inherit"
                  className="bar-negative"
                  value={Math.min(item[sortKey], 100)}
                />
              ) : (
                <BorderLinearProgress
                  variant="determinate"
                  color="inherit"
                  className="bar-neutral"
                  value={Math.min(item[sortKey], 100)}
                />
              )}
            </Box>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Topics;
