import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { colours, tabselected /*, tabselectedNopoint*/ } from "../../consts.js";
import { useNavigate } from "react-router-dom";
import Utils from "../../utils.js";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import FeatherIcon from "feather-icons-react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";

function TrackerAdmin() {
  let navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [dataedit, setDataEdit] = React.useState([]);
  const [companyDT, setCompanyDT] = React.useState({});
  const [newCampDiag, setNewCampDiag] = React.useState(false);
  const [selectedTab] = React.useState("Tracker admin");
  const [isAdmin, setIsadmin] = React.useState(false);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);

  async function getCompanyData() {
    Utils.sendProgressBar(true);
    setSpinner(true);
    let userData = await Utils.getUserGroupFromCognito();
    let user = userData;


    if (user.includes("admin")) {
      setIsadmin(true);
    }

    if (user.isAdmin === null || user.isAdmin === false) navigate("/");
    else {
      let companies = await Utils.genericGet("getCompany", "admin");
      companies = companies.data.getCompany;
      setCompanyDT(companies);
      companies.trackers = JSON.parse(companies.trackers);
      setDataEdit(
        companies.trackers.map((z) => {
          return { phrases: z.phrases.map((a) => false) };
        })
      );
      setData(companies.trackers);
    }
    setSpinner(false);
  }

  React.useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        await getCompanyData(); // Your async data fetching function
        setDataLoaded(true); // Mark data as loaded
      };
      fetchData();
    }

    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, [dataLoaded]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <NewCampaignD1
        open={newCampDiag}
        onClose={async (inp) => {
          if (inp === null) setNewCampDiag(false);
          else {
            Utils.sendProgressBar(true);
            let datac = Object.assign([], data);
            let today = new Date(Date.now());
            today = today.toISOString().substr(0, 10);
            datac.push({ name: inp, created: today, phrases: [] });
            await Utils.genericMutation("updateCompany", {
              id: companyDT.id,
              trackers: JSON.stringify(datac),
            });
            await getCompanyData();
            setNewCampDiag(false);
          }
        }}
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FeatherIcon
          icon="award"
          style={{ marginLeft: 20, marginBottom: 10 }}
        />
        <div
          style={{ marginLeft: 5 }}
          onClick={() => {
            navigate("/admin");
          }}
        >
          Admin /
        </div>
        <FeatherIcon icon="list" style={{ marginLeft: 5, marginBottom: 10 }} />
        <div className="menu-item-selected">Trackers</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: colours.dividergraybottomborder,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <div
            style={tabselected(selectedTab === "Companies")}
            onClick={() => {
              navigate("/admin");
            }}
          >
            Companies
          </div>
          <div
            style={tabselected(selectedTab === "Tracker admin")}
            onClick={() => {
              navigate("/trackeradmin");
            }}
          >
            Tracker admin
          </div>
          <div
            style={tabselected(selectedTab === "User setup")}
            onClick={() => {
              navigate("/adminusers");
            }}
          >
            User setup
          </div>
        </div>
      </div>
      <div className="vocalysed-home">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="vocalysd-card vocalysd-table">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: 30,
                marginTop: 10,
              }}
            >
              <div></div>
              <Button
                style={{
                  textTransform: "none",
                  float: "right",
                  borderRadius: 20,
                  marginRight: 20,
                  marginBottom: 20,
                  marginTop: 10,
                  background: colours.primary100,
                  fontFamily: "Poppins",
                }}
                variant="contained"
                onClick={() => setNewCampDiag(true)}
              >
                <FeatherIcon
                  icon="plus-circle"
                  style={{ color: colours.white, marginRight: 6 }}
                />
                Create new tracker
              </Button>
            </div>
            {spinner ? (
              <Accordion>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <CircularProgress style={{
                     color: colours.primary100,
                     marginLeft: 600 
                     }} />
                </AccordionSummary>

                <AccordionDetails>
                  <Table>
                    <TableHead>
                      <TableRow />
                    </TableHead>
                    <TableBody>
                      <TableRow></TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            ) : (
              data.map((a, aid) => (
                <Accordion key={aid}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{ fontFamily: "Poppins", fontWeight: "bold" }}
                    >
                      {a.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "bold",
                            }}
                          >
                            Phrases
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {a.phrases.map((b, bid) => (
                          <TableRow key={bid}>
                            <TableCell style={{ fontFamily: "Poppins" }}>
                              {!dataedit[aid].phrases[bid] ? (
                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: 16,
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>{b}</div>
                                  <div>
                                    <Button
                                      style={{
                                        textTransform: "none",
                                      }}
                                      variant="transparent"
                                      onClick={async () => {
                                        let datac = Object.assign([], dataedit);

                                        datac[aid].phrases[bid] =
                                          !datac[aid].phrases[bid];
                                        setDataEdit(datac);
                                      }}
                                    >
                                      <FeatherIcon
                                        icon="edit-2"
                                        style={{ color: colours.subduedgray }}
                                      />
                                    </Button>
                                    <Button
                                      style={{
                                        textTransform: "none",
                                      }}
                                      variant="transparent"
                                      onClick={async () => {
                                        let datac = Object.assign([], data);

                                        datac[aid].phrases.splice(bid, 1);
                                        await Utils.genericMutation(
                                          "updateCompany",
                                          {
                                            id: companyDT.id,
                                            trackers: JSON.stringify(datac),
                                          }
                                        );
                                        await getCompanyData();
                                      }}
                                    >
                                      <FeatherIcon
                                        icon="trash"
                                        style={{ color: colours.subduedgray }}
                                      />
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: 16,
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    label="Tracker"
                                    InputLabelProps={{
                                      style: { color: "#84858a" },
                                    }}
                                    onChange={(c) => {
                                      let datac = Object.assign([], data);
                                      datac[aid].phrases[bid] = c.target.value;
                                      setData(datac);
                                    }}
                                    value={data[aid].phrases[bid]}
                                  />
                                  <Button
                                    style={{
                                      textTransform: "none",
                                    }}
                                    variant="transparent"
                                    onClick={async () => {
                                      let datac = Object.assign([], data);

                                      await Utils.genericMutation(
                                        "updateCompany",
                                        {
                                          id: companyDT.id,
                                          trackers: JSON.stringify(datac),
                                        }
                                      );
                                      await getCompanyData();
                                    }}
                                  >
                                    Done
                                  </Button>
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell style={{ fontFamily: "Poppins" }}>
                            <Button
                              style={{
                                textTransform: "none",

                                borderRadius: 20,
                                marginRight: 20,
                                marginBottom: 20,
                                marginTop: 10,
                                fontFamily: "Poppins",
                                background: colours.primary100,
                              }}
                              variant="contained"
                              onClick={async () => {
                                let datac = Object.assign([], data);
                                datac[aid].phrases.push("");
                                let datac2 = Object.assign([], dataedit);
                                datac2[aid].phrases.push(true);
                                await Utils.genericMutation("updateCompany", {
                                  id: companyDT.id,
                                  trackers: JSON.stringify(datac),
                                });
                                await getCompanyData();

                                setDataEdit(datac2);
                              }}
                            >
                              <FeatherIcon
                                icon="plus-circle"
                                style={{ color: colours.white, marginRight: 5 }}
                              />
                              Add new phrase
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackerAdmin;

function NewCampaignD1({ open, onClose }) {
  const [tracker, setTracker] = React.useState("");

  return (
    <Dialog
      disableAutoFocus
      onClose={() => onClose(null)}
      onBackdropClick={() => {
        onClose(null);
      }}
      open={open}
      style={{
        padding: 0,

        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 30,
      }}
    >
      <DialogTitle style={{ padding: 0, minWidth: "600px", width: "600px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,

              fontFamily: "Poppins",
            }}
          >
            <div>New tracker</div>

            <FeatherIcon
              icon="x"
              style={{ color: "#ddd", cursor: "pointer" }}
              onClick={() => onClose(null)}
            />
          </div>
          <Divider style={{}} />
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            jus: "space-between",
            alignItems: "center",
            padding: 20,
            fontFamily: "Poppins",
          }}
        >
          <TextField
            id="outlined-basic"
            variant="outlined"
            style={{ width: "100%", marginTop: 20 }}
            label="Tracker"
            InputLabelProps={{
              style: { color: "#84858a" },
            }}
            onChange={(c) => {
              setTracker(c.target.value);
            }}
            value={tracker}
          />
        </div>
        <Divider style={{}} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 20,
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              float: "right",
              marginRight: 5,
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.white,
              color: colours.primary100,
              border: "1px solid #ddd",
              boxShadow: "none",
            }}
            variant="contained"
            onClick={() => onClose(null)}
          >
            Cancel
          </Button>
          <Button
            style={{
              textTransform: "none",
              float: "right",
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.primary100,
            }}
            variant="contained"
            onClick={() => {
              onClose(tracker);
            }}
          >
            Done
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
