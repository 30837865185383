import React from "react";
import { colours, TT } from "../../consts.js";
import FeatherIcon from "feather-icons-react";
import VIcon from "../images/Logo_black.png";
import ComingSoonIcon1 from "../images/attsoon.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Topics from "../../components/topics/Topics.js";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Utils from "../../utils";
import Chip from "@mui/material/Chip";
import {
  PieChart,
  Pie,
  Bar,
  BarChart,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const COLORS = [colours.purple100, colours.primary100, colours.subduedgray];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light" ? colours.primary100 : colours.primary100,
  },
}));

const BorderLinearProgressD = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#999" : "#999",
  },
}));

const BorderLinearProgressP = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light" ? colours.green100 : colours.green100,
  },
}));

function capcase(input) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}
function CampaignInsights() {
  let navigate = useNavigate();
  let location = useLocation();
  const [sentiment, setSentiment] = React.useState([]);
  const [callcount, setCallcount] = React.useState(0);
  let camid = location.search.replace("?id=", "");
  const [campaignID] = React.useState(camid);
  // const [campaignName, setCampaignName] = React.useState(camid);
  const [data, setData] = React.useState({});
  const [spinner, setSpinner] = React.useState(false);
  const [pickedAgent, setPickedAgent] = React.useState(true);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  async function getCampaignData() {
    Utils.sendProgressBar(true);
    setSpinner(true);
    let camids = location.search.replace("?id=", "");
    // setCampaignName(await Utils.genericGet("getCampaign", camids));
    let s = await Utils.genericGet("getCampaign", camids);
    s = s.data.getCampaign;
    s.VShits = JSON.parse(s.VShits);
    s.agenthits = JSON.parse(s.agenthits);
    s.alerthits = JSON.parse(s.alerthits);
    s.durationhits = JSON.parse(s.durationhits);
    s.topichits = JSON.parse(s.topichits);
    s.sentimenthits = JSON.parse(s.sentimenthits);
    s.trackerhits = JSON.parse(s.trackerhits);
    s.questionhits = JSON.parse(s.questionhits);
    s.analyticshits = JSON.parse(s.analyticshits);
    let totalsentiment =
      s.sentimenthits.neg + s.sentimenthits.pos + s.sentimenthits.neu;

    setSentiment([
      {
        name: "Negative",
        Negative: (s.sentimenthits.neg / totalsentiment) * 100,
      },
      {
        name: "Neutral",

        Neutral: (s.sentimenthits.neu / totalsentiment) * 100,
      },
      {
        name: "Positive",

        Positive: (s.sentimenthits.pos / totalsentiment) * 100,
      },
    ]);
    setData(s);

    let count = 0;
    let filter = {
      campaignConversationsId: { eq: s.id },
      limit: 2000,
    };
    //let counter = await Utils.genericListMOD5(filter);
    let counter = await Utils.genericListMOD(
      "listConversations",
      {
        filter: {
          campaignConversationsId: { eq: camids },
          archive: { ne: true },
        },
        limit: 1000,
      },
      true
    );

    count += counter.data.listConversations.items.length;

    let tkn = counter.data.listConversations.nextToken;
    while (tkn !== null) {
      counter = await Utils.genericListMOD(
        "listConversations",
        {
          filter: {
            campaignConversationsId: { eq: camids },
            archive: { ne: true },
          },
          limit: 1000,
          nextToken: tkn,
        },
        true
      );
      tkn = counter.data.listConversations.nextToken;
      count += counter.data.listConversations.items.length;
    }
    setCallcount(count);
    setSpinner(false);
  }

  React.useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        await getCampaignData(); // Your async data fetching function
        setDataLoaded(true); // Mark data as loaded
      };
      fetchData();
    }

    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, [dataLoaded]);

  const barColors = [
    ["#E7A500", "#E87522"],
    ["#DEE2F1", "#999DAC"],
    ["#21A500", "#1B8700"],
  ];
  const barColors2 = [
    ["#E7A500", "#E87522"],
    ["#9F40C1", "#782d92"],
    ["#DEE2F1", "#ECECEC"],
  ];

  //fix for over 100% changes speaker analytics
  if (data.analyticshits) {
    var checktotal =
      data.analyticshits.agenttalktime +
      data.analyticshits.clienttalktime +
      data.analyticshits.silence;
    checktotal = checktotal.toFixed(0);
    var silencetest =
      checktotal -
      data.analyticshits.agenttalktime -
      data.analyticshits.clienttalktime -
      0.5;
  } else {
    var silencetest = 0;
  }

  
  return (
    <div className="vocalysed-home">
      <div style={colours.vboxWide} className="vocalysd-card">
        <div style={colours.divstyle1}>
          <div style={colours.avatarBox}>
            <FeatherIcon icon="user" style={{ color: colours.dividergray }} />
          </div>
          <div>
            <div># of Agents</div>
            <div className="bold">
              {data.agenthits ? data.agenthits.length : 0}
            </div>
          </div>
        </div>
        <div style={colours.divstyle1}>
          <div style={colours.avatarBox}>
            <FeatherIcon
              icon="calendar"
              style={{ color: colours.dividergray }}
            />
          </div>
          <div>
            <div>Total calls</div>
            <div className="bold">
              {callcount === 0
                ? data.conversations
                  ? data.conversations.items.length
                  : 0
                : callcount}
            </div>
          </div>
        </div>
        <div style={colours.divstyle1}>
          <div style={colours.avatarBox}>
            <FeatherIcon icon="watch" style={{ color: colours.dividergray }} />
          </div>
          <div>
            <div>Avg. Call Duration</div>
            <div className="bold">
              {data.durationhits
                ? (
                    data.durationhits.duration /
                    data.durationhits.n /
                    60
                  ).toFixed(0) +
                  "min " +
                  (
                    (data.durationhits.duration / data.durationhits.n) %
                    60
                  ).toFixed(0) +
                  "sec"
                : 0}
            </div>
          </div>
        </div>
        <div style={colours.divstyle1}>
          <div style={colours.avatarBox}>
            <img src={VIcon} alt="" style={{ width: 20 }} />
          </div>
          <div>
            <div>Avg. Vocalysd score</div>
            <div className="bold" style={{ color: colours.green100 }}>
              {data.VShits ? (data.VShits.VS / data.VShits.n).toFixed(0) : 0}%
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          background: "#f8f9fd",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              minHeight: "220px",
              maxHeight: "220px",
              padding: "10px",
            }}
          >
            <div style={colours.vboxSmall} className="vocalysd-card">
              <div className="header-icon">
                <FeatherIcon icon="smile" />
              </div>
              <div
                style={{ display: "inline-block", verticalAlign: "super" }}
                className="vocalysd-heading"
              >
                Sentiment
              </div>
              <div style={{ width: "100%", height: 120 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    className="labellesstooltip"
                    data={sentiment}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      dataKey="name"
                      axisLine={{ stroke: "#DEE2F1" }}
                      fontFamily={"Poppins"}
                      fontSize={"12px"}
                    />
                    <YAxis
                      axisLine={{ stroke: "#DEE2F1" }}
                      domain={[0, 100]}
                      fontFamily={"Poppins"}
                      fontSize={"12px"}
                      tickFormatter={(d) => {
                        return d.toFixed(0) + "%";
                      }}
                    />
                    <Tooltip
                      key="tooltipbar"
                      formatter={(v, n, p) => {
                        return v && v.toFixed ? v.toFixed(0) + "%" : "%";
                      }}
                    />
                    <defs>
                      {barColors.map((color, index) => (
                        <linearGradient
                          id={`colorUv${index}`}
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="100%"
                          spreadMethod="reflect"
                        >
                          <stop offset="0" stopColor={color[0]} />
                          <stop offset="1" stopColor={color[1]} />
                        </linearGradient>
                      ))}
                    </defs>
                    <Bar
                      dataKey="Negative"
                      stackId="a"
                      fill={colours.primary100}
                      radius={[10, 10, 0, 0]}
                    >
                      {sentiment.map((entry, index) => (
                        <Cell key={`cell0-${index}`} fill={`url(#colorUv0)`} />
                      ))}
                    </Bar>
                    <Bar
                      dataKey="Neutral"
                      stackId="a"
                      fill={colours.dividergray}
                      radius={[10, 10, 0, 0]}
                    >
                      {sentiment.map((entry, index) => (
                        <Cell key={`cell1-${index}`} fill={`url(#colorUv1)`} />
                      ))}
                    </Bar>
                    <Bar
                      dataKey="Positive"
                      stackId="a"
                      fill={colours.green100}
                      radius={[10, 10, 0, 0]}
                    >
                      {sentiment.map((entry, index) => (
                        <Cell key={`cell2-${index}`} fill={`url(#colorUv2)`} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div style={colours.vboxSmall} className="vocalysd-card">
              <div className="header-icon">
                <FeatherIcon icon="alert-triangle" />
              </div>
              <div
                style={{ display: "inline-block", verticalAlign: "super" }}
                className="vocalysd-heading"
              >
                Alerts
              </div>
              <div>
                {!data.alerthits
                  ? null
                  : data.alerthits.map((z, zi) => (
                      <Chip
                        key={zi}
                        label={z.alert}
                        style={{
                          background: "#E87522",
                          color: "#fff",
                          fontFamily: "Poppins",
                        }}
                      />
                    ))}
              </div>
            </div>
          </div>
          <div className="vocalysd-card">
            <div className="header-icon">
              <FeatherIcon icon="activity" />
            </div>
            <div
              style={{
                marginBottom: 16,
                display: "inline-block",
                verticalAlign: "super",
              }}
              className="vocalysd-heading"
            >
              Trackers
            </div>
            <div>
              {!data.trackerhits
                ? null
                : data.trackerhits
                    .slice(0, 20)
                    .sort((a, b) => b.hits - a.hits)
                    .map((a, ax) => (
                      <div
                        key={ax}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "30%",
                            textAlign: "right",
                            marginRight: 10,
                            marginBottom: 5,
                            fontSize: 12,
                          }}
                        >
                          {capcase(a.tracker)}
                        </div>
                        <Box sx={{ color: colours.subduedgray, width: "65%" }}>
                          <BorderLinearProgressD
                            variant="determinate"
                            color="inherit"
                            value={Math.min(a.hits, 100)}
                          />
                        </Box>
                      </div>
                    ))}
            </div>
          </div>
          <div className="vocalysd-card">
            <div
              style={{
                display: "block",
                /*flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",*/
              }}
            >
              <div className="header-icon">
                <FeatherIcon icon="check-circle" />
              </div>
              <div
                style={{ display: "inline-block", verticalAlign: "super" }}
                className="vocalysd-heading"
              >
                Top questions
              </div>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                style={{
                  float: "right",
                }}
                className="buttons-border-extend"
              >
                <Button
                  onClick={() => setPickedAgent(true)}
                  style={{
                    textTransform: "none",
                    background: pickedAgent
                      ? colours.primary100
                      : colours.white,
                    boxShadow: pickedAgent
                      ? "none"
                      : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                    color: pickedAgent ? colours.white : colours.black,
                    fontFamily: "Poppins",
                  }}
                >
                  Agent
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    background: !pickedAgent
                      ? colours.primary100
                      : colours.white,
                    boxShadow: !pickedAgent
                      ? "none"
                      : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                    color: !pickedAgent ? colours.white : colours.black,
                    fontFamily: "Poppins",
                  }}
                  onClick={() => setPickedAgent(false)}
                >
                  Client
                </Button>
              </ButtonGroup>
            </div>
            <div style={{ marginTop: 16 }}>
              <Table>
                <TableBody>
                  {!data.questionhits ? (
                    <TableRow style={{ fontFamily: "Poppins" }} />
                  ) : (
                    data.questionhits
                      .filter((z) =>
                        pickedAgent ? z.speaker === "Speaker 2" : "Speaker 1"
                      )
                      .sort((a, b) => b.n - a.n)
                      .slice(0, 7)
                      .map((z, zi) => (
                        <TableRow key={zi}>
                          <TableCell
                            style={{
                              paddingTop: 3,
                              paddingBottom: 3,
                              fontFamily: "Poppins",
                            }}
                          >
                            {zi + 1 + ". " + z.text}
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                minHeight: "220px",
                maxHeight: "220px",
                padding: "10px",
              }}
            >
              <div
                className="vocalysd-card"
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <div className="header-icon">
                    <FeatherIcon icon="pie-chart" />
                  </div>
                  <div
                    style={{ display: "inline-block", verticalAlign: "super" }}
                    className="vocalysd-heading"
                  >
                    Speaker analytics
                  </div>
                </div>

                <div style={{ width: "100%", height: 130 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart width="100%" height={300} className="piechart">
                      <defs>
                        {barColors2.map((color, index) => (
                          <linearGradient
                            id={`color2Uv${index}`}
                            x1="0"
                            y1="0"
                            x2="100%"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor={color[0]} />
                            <stop offset="1" stopColor={color[1]} />
                          </linearGradient>
                        ))}
                      </defs>
                      <Pie
                        dataKey="value"
                        startAngle={360}
                        endAngle={0}
                        data={
                          !data.analyticshits
                            ? []
                            : [
                                {
                                  name: "Agent",
                                  value: data.analyticshits.agenttalktime,
                                },
                                {
                                  name: "Client",
                                  value: data.analyticshits.clienttalktime,
                                },
                                {
                                  name: "Silence",
                                  value: silencetest,
                                },
                              ]
                        }
                        cx="50%"
                        cy="50%"
                        outerRadius={50}
                        fill="#8884d8"
                      >
                        {!data.analyticshits
                          ? []
                          : [
                              {
                                name: "Agent",
                                value:
                                  data.analyticshits.agenttalktime.toFixed(0),
                              },
                              {
                                name: "Client",
                                value:
                                  data.analyticshits.clienttalktime.toFixed(0),
                              },
                              {
                                name: "Silence",
                                value: data.analyticshits.silence.toFixed(0),
                              },
                            ].map((entry, index) => (
                              <Cell
                                key={`cell5-${index}`}
                                fill={`url(#color2Uv${index})`}
                              />
                            ))}
                      </Pie>

                      <Legend
                        align="right"
                        layout="vertical"
                        verticalAlign="middle"
                        width="80px"
                        iconType="circle"
                        formatter={(v, e, i) => {
                          return (
                            v + " " + (e.payload.percent * 100).toFixed(0) + "%"
                          );
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div
                className="vocalysd-card"
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <div className="header-icon">
                    <FeatherIcon icon="check-circle" />
                  </div>
                  <div
                    style={{ display: "inline-block", verticalAlign: "super" }}
                    className="vocalysd-heading"
                  >
                    Avg. WPM agent vs client
                  </div>
                </div>
                <div style={{ width: "100%", height: 120 }}>
                  <img src={ComingSoonIcon1} alt="" style={{ width: "100%" }} />
                </div>
              </div>
            </div>
            <div className="vocalysd-card">
              <div className="vocalysd-heading">
              <div className="header-icon">
                <FeatherIcon icon="message-circle" />
              </div>
                Topics
              </div>
              
              

              <Topics
                topichits={data.topichits}
                capcase={capcase}
                color={colours}
                maxItems = {20}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignInsights;
