import React from "react";
//import Button from "@mui/material/Button";
//import TextField from "@mui/material/TextField";
//import AdapterDateFns from "@mui/lab/AdapterDateFns";
//import LocalizationProvider from "@mui/lab/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
//import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
//import DatePicker from "@mui/lab/DatePicker";
import { colours, tabselected, TTT, playlistFilters, playlistFilterDescriptions } from "../../consts.js";
import { useNavigate } from "react-router-dom";
//import Checkbox from "@mui/material/Checkbox";
//import InputAdornment from "@mui/material/InputAdornment";
import Utils from "../../utils.js";
//import CircularProgress from "@mui/material/CircularProgress";
import FeatherIcon from "feather-icons-react";
import SearchBar from "../../components/search/searchbar.js";

function App() {
    let navigate = useNavigate();
    const [data, setData] = React.useState([]);
    const [initialData, setInitialData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);
    const [selectedPlaylistFilter, setSelectedPlaylistFilter] = React.useState("Snippets");
    const [playlistFilterDescription, setPlaylistFilterDescription] = React.useState(playlistFilterDescriptions.snippets);
    const [searchValue, setSearchValue] = React.useState("");
    const [dataLoaded, setDataLoaded] = React.useState(false);

    const filterData = searchValue => {

        if(searchValue === "") {
            return initialData;
        } else {
            return initialData.filter(z => z.file.toLowerCase().includes(searchValue.toLowerCase()) || z.agent.toLowerCase().includes(searchValue.toLowerCase()) || z.campaign.name.toLowerCase().includes(searchValue.toLowerCase()))
        }
    }

    async function getCampaignData() {
        Utils.sendProgressBar(true);
        setSpinner(true);
        let s = await Utils.genericList("listConversations", {
            filter: { bookmarks: { attributeExists: true } },
            limit: 1000,
        });

        setInitialData(s.data.listConversations.items);
        setData(s.data.listConversations.items);
        setSpinner(false);
    }

   React.useEffect(() => {
        if (!dataLoaded) {
          const fetchData = async () => {
            await getCampaignData(); // Your async data fetching function
            setDataLoaded(true); // Mark data as loaded
          };
          fetchData();
        }
    
        window.scrollTo(0, 0); // Scroll to top when component mounts
    
      }, [dataLoaded]);

    React.useEffect(() => {
        const filteredData = filterData(searchValue);
        setData(filteredData);
    }, [searchValue]);

    function doFilter(vals, playlistFilter) {
        if(playlistFilter === "Snippets") return true;
        else {
            return vals.bookmarks.indexOf(playlistFilter) > -1;
        }
    }

    function updatePlaylistFilter(filter) {

        setSelectedPlaylistFilter(filter);
        Utils.sendProgressBar(true);

        if(filter === "Snippets") {
            setPlaylistFilterDescription(playlistFilterDescriptions.snippets);
        } else if(filter === "WOW moments") {
            setPlaylistFilterDescription(playlistFilterDescriptions.wowMoments);
        }
        else if(filter === "High risk") {
            setPlaylistFilterDescription(playlistFilterDescriptions.highRisk);
        }
        else if(filter === "Great interactions") {
            setPlaylistFilterDescription(playlistFilterDescriptions.greatInteractions);
        }
        else if(filter === "Poor interactions") {
            setPlaylistFilterDescription(playlistFilterDescriptions.poorInterations);
        }
        else if(filter === "Other") {
            setPlaylistFilterDescription(playlistFilterDescriptions.other);
        }
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: colours.dividergraybottomborder,
                }}
            >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormControl fullWidth style={{ width: "200px", marginLeft: "20px", marginBottom: "15px", }}>
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPlaylistFilter}
                            defaultValue=""
                            label="Filter"
                            onChange={(event) => {
                                updatePlaylistFilter(event.target.value);
                            }}
                        >
                            {playlistFilters.map((playlist) => (
                                <MenuItem value={playlist} key={playlist}>
                                    {playlist}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TTT
                        info={
                            playlistFilterDescription
                        }
                    />

                </div>
                <div className="search-bar-alignment">
                    <SearchBar callBack={(searchValue) => setSearchValue(searchValue)} />
                </div>
            </div>
            <div className="vocalysed-home">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div className="vocalysd-card vocalysd-table">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            File
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Agent
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Campaign
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Conversation date
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Sentiment
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            
                            <TableBody>
                            {spinner ? (
                                    <TableRow>
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                        <TableCell>
                                            <CircularProgress style={{ color: colours.primary100 }} />
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                ) :
                                data
                                    .filter((z) => doFilter(z, selectedPlaylistFilter))
                                    .map((z, zidx) => (
                                        <TableRow key={zidx}>
                                            <TableCell
                                                onClick={() => {
                                                    navigate("/conversation?id=" + z.id);
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontWeight: "bold",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        fontFamily: "Poppins",
                                                    }}
                                                >
                                                    <div className="feather-icon-bordered">
                                                        <FeatherIcon icon="play" />
                                                    </div>
                                                    <div style={{ marginLeft: 10 }}>{z.file}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ fontFamily: "Poppins" }}>{z.agent}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div
                                                    style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                                                >
                                                    {z.campaign.name}
                                                </div>
                                                <div style={{ fontFamily: "Poppins" }}>{z.company}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div>{z.startdate}</div>
                                            </TableCell>
                                            <TableCell style={{ fontFamily: "Poppins" }}>
                                                {z.sentiment === "positive" ? (
                                                    <div style={{ color: colours.green100 }}>
                                                        Positive
                                                    </div>
                                                ) : null}
                                                {z.sentiment === "negative" ? (
                                                    <div style={{ color: colours.critical100 }}>
                                                        Negative
                                                    </div>
                                                ) : null}
                                                {z.sentiment === "Neutral" ? (
                                                    <div style={{ color: colours.black }}>Neutral</div>
                                                ) : null}
                                                {z.sentiment === null ? (
                                                    <div style={{ color: colours.black }}>Neutral</div>
                                                ) : null}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
