import React from 'react';
import { Drawer } from '@mui/material';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import LogoWhite from "../../pages/images/Logo_white.png";

const CustomDrawer = ({ sizeOfMenuDrawer, pagename, setPagename, colours, admincheck, Auth, ProjectVersion }) => {
    return (
        <Drawer
            anchor={"left"}
            variant="permanent"
            open={true}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    borderRight: 0,
                    boxSizing: "border-box",
                },
            }}
        >
            <div
                style={{
                    width: sizeOfMenuDrawer,
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    background: "#000",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        background: "#000",
                        overflow: "hidden",
                    }}
                >
                    <Link
                        to="/"
                        onClick={() => setPagename("Analytics")}
                        style={{ textDecoration: "none", marginTop: 80 }}
                    >
                        <div
                            style={
                                pagename === "Analytics"
                                    ? colours.menuStyleSelected
                                    : colours.menuStyle
                            }
                        >
                            <img src={LogoWhite} alt="" style={{ width: 30, marginBottom: "2px" }} />
                            Analytics
                        </div>
                    </Link>
                    <Link
                        to="/campaigns"
                        onClick={() => setPagename("Campaigns")}
                        style={{ textDecoration: "none" }}
                    >
                        <div
                            style={
                                pagename === "Campaigns"
                                    ? colours.menuStyleSelected
                                    : colours.menuStyle
                            }
                        >
                            <FeatherIcon
                                icon="message-square"
                                style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }}
                            />
                            Campaigns
                        </div>
                    </Link>
                    <Link
                        to="/playlists"
                        onClick={() => setPagename("Playlists")}
                        style={{ textDecoration: "none" }}
                    >
                        <div
                            style={
                                pagename === "Playlists"
                                    ? colours.menuStyleSelected
                                    : colours.menuStyle
                            }
                        >
                            <FeatherIcon icon="play-circle" style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }} />
                            Playlists
                        </div>
                    </Link>
                    <Link
                        to="/trackers"
                        onClick={() => setPagename("Trackers")}
                        style={{ textDecoration: "none" }}
                    >
                        <div
                            style={
                                pagename === "Trackers"
                                    ? colours.menuStyleSelected
                                    : colours.menuStyle
                            }
                        >
                            <FeatherIcon icon="loader" style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }} />
                            Trackers
                        </div>
                    </Link>

                    <Link
                        to="/organisation"
                        onClick={() => setPagename("Organisation")}
                        style={{ textDecoration: "none" }}
                    >
                        <div
                            style={
                                pagename === "Organisation"
                                    ? colours.menuStyleSelected
                                    : colours.menuStyle
                            }
                        >
                            <FeatherIcon icon="briefcase" style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }} />
                            Organisation
                        </div>
                    </Link>

                    {admincheck ? (
                        <Link
                            to="/admin"
                            onClick={() => setPagename("Admin")}
                            style={{ textDecoration: "none" }}
                        >
                            <div
                                style={
                                    pagename === "Admin"
                                        ? colours.menuStyleSelected
                                        : colours.menuStyle
                                }
                            >
                                <FeatherIcon icon="edit" style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }} />
                                Admin
                            </div>
                        </Link>
                    ) : null}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >

                    </div>
                </div>
                <div>
                    <Link
                        to="/"
                        onClick={() => Auth.signOut()}
                        style={{ textDecoration: "none" }}
                    >
                        <div style={colours.menuStyle}>
                            <FeatherIcon icon="log-out" style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }} />
                            Log out
                        </div>
                    </Link>
                    <div
                        style={{
                            color: colours.white,
                            fontSize: 12,
                            textAlign: "center",
                            marginBottom: 16,
                        }}
                    >
                        {"v" + ProjectVersion}
                    </div>
                    <div
                        style={{
                            width: "50%",
                            margin: "auto",
                            marginBottom: 16,
                            borderTop: "1px solid #fff",
                            textAlign: "center",
                        }}
                    ></div>
                    <Link
                        to="/privacy"
                        style={{
                            textDecoration: "none",
                            color: colours.white,
                            fontSize: 10,
                            display: "block",
                            textAlign: "center",
                            marginTop: "16px",
                            marginBottom: "30px",
                        }}
                    >
                        Privacy policy
                    </Link>
                </div>
            </div>
        </Drawer>
    );
};

export default CustomDrawer;