import React, { useState } from "react";
import DatePickerComponent from "../../components/date-picker/date-picker";
import { colours } from "../../consts.js";
import AgentAnalytics from "./home-agent-analystics.js";
import ComplianceCard from "./home-compliance-card.js";
import PlaylistsCard from "./home-playlist-card.js";
import SentimentChart from "./home-sentiment-chart";
import TopAlertsCard from "./home-top-alerts-card.js";
import TopQuestionsCard from "./home-top-questions.js";
import TopTrackersCard from "./home-top-trackers-card.js";
import TopicSentimentChart from "./home-topic-sentiment-chart";
import VocalysdScore from "./home-vocalysd-score.js";
import TalkRatioCard from "./homr-talk-ratio-card.js";
import CircularProgress from "@mui/material/CircularProgress";

import Utils from "../../utils.js";

let senti = [];

function capcase(input) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

function Home() {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);


  const [data, setData] = React.useState({});
  const [pldata, setPLData] = React.useState([]);
  const [sentiment, setSentiment] = React.useState([]);
  const [topicdata, settopicdata] = React.useState([]);
  const [listofagents, setListofagents] = React.useState([]);
  const [allscore, setAllscore] = React.useState(0);
  const [spinner, setSpinner] = React.useState(false);
  const [pickedAgent, setPickedAgent] = React.useState(true);
  const [pickedAgentTOP, setPickedAgentTOP] = React.useState(true);

  var formatedFromDate;
  var formatedToDate;
  var dateFlag = false;
  const checkRange = (sentiments, fromDate, toDate) => {
    let sentimentMonths = [];

    if (dateFlag) {
      sentiments.forEach((sentiment) => {
        const sentimentMonth = sentiment.month; // Assuming month is in 'YYYY-MM' format

        // Compare the sentiment month with fromDate and toDate
        if (sentimentMonth >= fromDate || sentimentMonth <= toDate) {
          sentimentMonths.push(sentiment); // Add the sentiment to the result if it falls within the range
        }
      });
    } else {
      sentiments.forEach((sentiment) => {
        sentimentMonths.push(sentiment); // Add the sentiment to the result if it falls within the range
      });
    }

    return sentimentMonths; // Return the filtered sentiments
  };

  async function getCampaignData() {
    Utils.sendProgressBar(true);
    setSpinner(true);

    let userData = await Utils.getUserGroupFromCognito();
    let user = userData.filter((z) => z !== "admin");

    let companyData = await Utils.genericList("listCompanies", {
      filter: { internalname: { eq: user[0] } },
    });

    let company = companyData.data.listCompanies.items[0].id;
    let companyGet = await Utils.genericGet("getCompany", company);
    let s = companyGet.data.getCompany;

    if (s.VShits === null) {
      return;
    }

    try {
      s.VShits = JSON.parse(s.VShits);
      let score = s.VShits.VS / s.VShits.n;
      setAllscore(score);
    } catch (error) {
      console.error("Error parsing VShits or calculating score:", error);
    }

    s.agenthits = JSON.parse(s.agenthits);
    s.alerthits = JSON.parse(s.alerthits);
    s.durationhits = JSON.parse(s.durationhits);
    s.topichits = JSON.parse(s.topichits);
    
    if (s.topichits === null) {
      return;
    }

    let newtopicdata = s.topichits
      .map((ss) => ({
        keyword: capcase(ss.topic) || capcase(ss.alert),
        x: 10,
        y: 10,
        z: ss.n,
        sen: ss.s === "pos" ? "pos" : ss.s === "neg" ? "neg" : "neu",
      }))
      .filter((z) => z.z > 10 && z.sen !== "neu")
      .slice(0, 10);

    // Predefined coordinates for up to 10 items
    const coordinates = [
      { x: 10, y: 10 },
      { x: 12.5, y: 11 },
      { x: 10, y: 17 },
      { x: 15, y: 12 },
      { x: 7, y: 16 },
      { x: 18, y: 12 },
      { x: 6, y: 5 },
      { x: 12, y: 5 },
      { x: 13, y: 18 },
      { x: 4, y: 9 },
    ];

    // Assign predefined coordinates to `newtopicdata` items
    newtopicdata = newtopicdata.map((item, index) => ({
      ...item,
      x: coordinates[index]?.x || 10, // Fallback to 10 if index exceeds predefined coordinates
      y: coordinates[index]?.y || 10, // Fallback to 10 if index exceeds predefined coordinates
    }));

    settopicdata(newtopicdata);
    // Parse the JSON objects
    if (s && typeof s === "object") {
      ["sentimenthits", "trackerhits", "questionhits", "analyticshits"].forEach(
        (key) => {
          if (s[key]) {
            s[key] = JSON.parse(s[key]);
          }
        }
      );
    } else {
      console.error("s is undefined or not an object:", s);
    }

    // Helper function to calculate sentiment percentages
    const calculateSentiment = (values) => {
      const total = values.neg + values.pos + values.neu;
      if (total === 0) return { Negative: 0, Positive: 0 }; // Prevent division by zero
      return {
        Negative: (values.neg / total) * 100,
        Positive: (values.pos / total) * 100,
      };
    };

    // Function to get sentiment data for a given month
    const getSentimentData = (hits) => {
      console.log(hits);
      // Filter hits to only include months within the specified date range
      const valuesInRange = checkRange(hits, formatedFromDate, formatedToDate);

      if (valuesInRange.length === 0) return null; // If no values found, return null

      // Map over the filtered array to calculate sentiment for each month

      const sentiment = valuesInRange.map((value) => ({
        name: value.month,
        ...calculateSentiment(value.values),
      }));

      return sentiment; // Return the array of sentiment data
    };

    // Collect sentiment data for today, last 30 days, and last 60 days
    // senti.push(getSentimentData(s.sentimenthits, today));
    senti = getSentimentData(s.sentimenthits);

    if (senti === null) return null;

    setSentiment(senti);

    setData(s);
    setSpinner(false);
    let pl = await Utils.genericListMOD2(
      "listConversations",
      {
        filter: { bookmarks: { attributeExists: true } },
        limit: 1000,
      },
      true
    );
    setPLData(pl.data.listConversations.items);
    let conv = await Utils.genericListMOD3(null);
    let nt = conv.data.listConversations.nextToken;

    while (nt !== null) {
      let newload = await Utils.genericListMOD3(nt);
      nt = newload.data.listConversations.nextToken;
      conv.data.listConversations.items =
        conv.data.listConversations.items.concat(
          newload.data.listConversations.items
        );
    }

    conv = conv.data.listConversations;
    conv = conv.items.filter((z) => z.VS !== null);
    let agentslist = [];
    conv.forEach((z) => {
      let found = agentslist.find((a) => a.agent === z.agent);
      if (found) {
        found.VS += parseInt(z.VS);
        found.n += 1;
      } else {
        agentslist.push({ agent: z.agent, n: 1, VS: parseInt(z.VS) });
      }
    });

    setListofagents(
      agentslist.map((z) => {
        return {
          agent: z.agent,
          VS: z.VS / z.n,
        };
      })
    );
  }
  React.useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        await getCampaignData(); 
        // Your async data fetching function
        setDataLoaded(true); // Mark data as loaded
      };
      fetchData();
    }

    window.scrollTo(0, 0); // Scroll to top when component mounts

  }, [dataLoaded]);

  var sentimentdata = sentiment;
  sentimentdata.map((senti, i) => {
    if (senti != null) {
        var d = new Date(senti.name);
        var formattedDate = `${d.getFullYear()}-${String(
          d.getMonth() + 1
        ).padStart(2, "0")}`;
        sentimentdata[i].name = formattedDate;
      }
    
  });

  const barColors = [
    ["#21A500", "#1B8700"],
    ["#DEE2F1", "#999DAC"],
    ["#E7A500", "#E87522"],
  ];

  return (
    <div
      className="vocalysed-home analytics"
      style={{
        display: "flex",
        flexDirection: "row",
        minHeight: "100vh",
      }}
    >
      {spinner && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <CircularProgress style={{ color: colours.primary100 }} />
        </div>
      )}
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
        }}
      >

        <SentimentChart
          sentimentdata={sentimentdata}
          barColors={barColors}
          colours={colours}
        />

        <TopicSentimentChart topicdata={topicdata} colours={colours} />

        <AgentAnalytics
          pickedAgentTOP={pickedAgentTOP}
          setPickedAgentTOP={setPickedAgentTOP}
          colours={colours}
          listofagents={listofagents}
        />
      </div>

      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <VocalysdScore allscore={allscore} colours={colours} />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexShrink: 1,
            flexDirection: "row",
          }}
        >
          <ComplianceCard data={data} colours={colours} />

          <TopTrackersCard data={data} colours={colours} />
        </div>

        <PlaylistsCard pldata={pldata} colours={colours} />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <TalkRatioCard data={data} colours={colours} />

          <TopAlertsCard data={data} colours={colours} />
        </div>

        <TopQuestionsCard
          data={data}
          pickedAgent={pickedAgent}
          setPickedAgent={setPickedAgent}
          colours={colours}
        />

{
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            height: "100vh",
            width: "300px", // Adjust width as needed
            transform: isDatePickerVisible
              ? "translateX(0)"
              : "translateX(100%)",
            transition: "transform 0.3s ease-in-out",
            boxShadow: isDatePickerVisible
              ? "0px 0px 10px rgba(0,0,0,0.3)"
              : "none",
            backgroundColor: "#fff",
            zIndex: 1000, // Ensure it overlays other content
          }}
        >
          <DatePickerComponent />
          
        </div>
      }

      </div>
      
    </div>
  );
}

export default Home;
