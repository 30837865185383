import React, { useState } from "react";
import { colours, TT } from "../../consts.js";
import Chip from "@mui/material/Chip";
import FeatherIcon from "feather-icons-react";
import VIcon from "../images/Logo_black.png";
import ComingSoonIcon1 from "../images/attsoon.jpg";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  PieChart,
  Pie,
  Bar,
  BarChart,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

import { useLocation } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Utils from "../../utils";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light" ? colours.primary100 : colours.primary100,
  },
}));

function capcase(input) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}
const BorderLinearProgressD = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#999" : "#999",
  },
}));

const BorderLinearProgressP = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light" ? colours.green100 : colours.green100,
  },
}));

function ConversationInsight() {
  // let location = useLocation();
  let history = useLocation().search.replace("?id=", "");
  const [conversation, setConversation] = useState({});
  const [summary, setSummary] = useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  const [analytics, setAnalytics] = useState({
    duration: "0",
    agent: "a",
    vs: "0",
    date: "",
  });
  const [sentiment, setSentiment] = useState([]);
  // const [conversationFile, setConversationFile] = useState("");
  const [pickedAgent, setPickedAgent] = React.useState(true);

  function calcSentiment(input) {
    let pos = 0;
    let neg = 0;
    let neutral = 0;

    input.forEach((z) => {
      if (z.sentiment) {
        if (z.sentiment.polarity.score < -0.5) neg += 1;
        else if (z.sentiment.polarity.score > 0.5) pos += 1;
        else neutral += 1;
      }
    });
    return { pos, neg, neutral };
  }

  const replaceSpeakers = (z, speaker1Label, speaker2Label) => {
    if (z.text) {
        z.text = z.text
            .replace(/Speaker 1/gi, speaker1Label) // Replace "Speaker 1" case-insensitively
            .replace(/Speaker 2/gi, speaker2Label); // Replace "Speaker 2" case-insensitively
    }
};

  async function getConversationData() {
    let conversationdata = await Utils.genericGet("getConversation", history);
    conversationdata.data.getConversation.analytics = JSON.parse(
      conversationdata.data.getConversation.analytics
    );
    conversationdata.data.getConversation.questions = JSON.parse(
      conversationdata.data.getConversation.questions
    );
    conversationdata.data.getConversation.summary = JSON.parse(
      conversationdata.data.getConversation.summary
    );
    conversationdata.data.getConversation.topics = JSON.parse(
      conversationdata.data.getConversation.topics
    );
    conversationdata.data.getConversation.trackersdetected = JSON.parse(
      conversationdata.data.getConversation.trackersdetected
    );
    conversationdata.data.getConversation.transcript = JSON.parse(
      conversationdata.data.getConversation.transcript
    );
    let totalsentiment =
      conversationdata.data.getConversation.transcript.messages.length;

    setSentiment([
      {
        name: "Negative",
        Negative: (
          (conversationdata.data.getConversation.transcript.messages.filter(
            (z) => (z.sentiment ? z.sentiment.polarity.score <= -0.5 : false)
          ).length /
            totalsentiment) *
          100
        ).toFixed(0),
      },
      {
        name: "Neutral",

        Neutral: (
          ((totalsentiment -
            conversationdata.data.getConversation.transcript.messages.filter(
              (z) => (z.sentiment ? z.sentiment.polarity.score >= 0.5 : false)
            ).length -
            conversationdata.data.getConversation.transcript.messages.filter(
              (z) => (z.sentiment ? z.sentiment.polarity.score <= -0.5 : false)
            ).length) /
            totalsentiment) *
          100
        ).toFixed(0),
      },
      {
        name: "Positive",

        Positive: (
          (conversationdata.data.getConversation.transcript.messages.filter(
            (z) => (z.sentiment ? z.sentiment.polarity.score >= 0.5 : false)
          ).length /
            totalsentiment) *
          100
        ).toFixed(0),
      },
    ]);
    let duration = conversationdata.data.getConversation.analytics.metrics.find(
      (z) => z.type === "total_talk_time"
    ).seconds;
    let mins = Math.floor(duration / 60);
    let secs = Math.floor(duration % 60);
    duration = mins + "min " + secs + "sec";
    let date = conversationdata.data.getConversation.startdate;
    let agent = conversationdata.data.getConversation.agent;
    let vs = (conversationdata.data.getConversation.VS || 0) + "%";
    setAnalytics({ date, vs, agent, duration });
    setConversation(conversationdata.data.getConversation);
    let newsummary = conversationdata.data.getConversation.summary.summary
    let atag = conversationdata.data.getConversation.agenttag

    try {
      if (atag === "Speaker 1") {
        newsummary.forEach(z => {
            replaceSpeakers(z, "Agent", "Client");
        });
    } else {
        newsummary.forEach(z => {
            replaceSpeakers(z, "Client", "Agent");
        });
    }

    }catch(error){
      console.log("Error processing summary", error);
    }

    setSummary(newsummary);

  }

  React.useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        await getConversationData();
        setDataLoaded(true);
      };
      fetchData();
    }
  }, [dataLoaded]);
  
  const barColors = [
    ["#E7A500", "#E87522"],
    ["#DEE2F1", "#999DAC"],
    ["#21A500", "#1B8700"],
  ];
  const barColors2 = [
    ["#E7A500", "#E87522"],
    ["#9F40C1", "#782d92"],
    ["#DEE2F1", "#ECECEC"],
  ];
  
  let summarytext = summary.map((z) => z.text).join(" ");
  let summaryshort = summarytext.slice(0, 350);
  let summarylong = summarytext.slice(350, summarytext.length);

  return (
    <div className="vocalysed-home">
      <div style={colours.vboxWide} className="vocalysd-card card-multiple">
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }} className="card-piece">
            <div style={colours.divstyle1}>
              <div style={colours.avatarBox}>
                <FeatherIcon
                  icon="user"
                  style={{ color: colours.dividergray }}
                />
              </div>
              <div>
                <div>Agent</div>
                <div className="bold">{analytics.agent}</div>
              </div>
            </div>
            <div style={colours.divstyle1}>
              <div style={colours.avatarBox}>
                <FeatherIcon
                  icon="calendar"
                  style={{ color: colours.dividergray }}
                />
              </div>
              <div>
                <div>Date</div>
                <div className="bold">{analytics.date}</div>
              </div>
            </div>
            <div style={colours.divstyle1}>
              <div style={colours.avatarBox}>
                <FeatherIcon
                  icon="watch"
                  style={{ color: colours.dividergray }}
                />
              </div>
              <div>
                <div>Call Duration</div>
                <div className="bold">{analytics.duration}</div>
              </div>
            </div>
            <div style={colours.divstyle1}>
              <div style={colours.avatarBox}>
                <img src={VIcon} alt="" style={{ width: 20 }} />
              </div>
              <div>
                <div>Avg. Vocalysd score</div>
                <div style={{ color: colours.green100 }} className="bold">{analytics.vs}</div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="card-piece">
            <div>
              <b>Conversation Summary</b>
            </div>
            <input type="checkbox" class="read-more-state" id="post-2" />
            <div class="read-more-wrap">
                {summarytext.length > 350
                    ? summaryshort
                    : summarytext
                }
                              
                {summarytext.length > 350
                    ? <span class="read-more-target2">...</span>
                    : ""
                }
                
                {summarytext.length > 350
                    ? <span class="read-more-target">{summarylong}</span>
                    : ""
                }
            </div>
            {summarytext.length > 30
                ? <label for="post-2" class="read-more-trigger"></label>
                : ""
            }
            
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          background: "#f8f9fd",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={colours.vboxSmall} className="vocalysd-card">
              <div className="header-icon">
                <FeatherIcon icon="smile" />
              </div>
              <div style={{display: "inline-block", verticalAlign: "super"}} className="vocalysd-heading">Sentiment</div>
              <div style={{ width: "100%", height: 120 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={sentiment}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="name" axisLine={{ stroke: "#DEE2F1" }} fontFamily={"Poppins"}/>
                    <YAxis
                      axisLine={{ stroke: "#DEE2F1" }}
                      domain={[0, 100]}
                      fontFamily={"Poppins"}
                      tickFormatter={(d) => {
                          return (d != null?d.toFixed(0) + "%":"%");
                      }}
                    />
                    <Tooltip
                    key="tooltipbar"
                      formatter={(v, n, p) => {
                        return (v && v.toFixed?v.toFixed(0) + "%":"%");
                      }}
                    />
                    <defs>
                        {barColors.map((color, index) => (
                          <linearGradient
                            id={`colorUv${index}`}
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor={color[0]} />
                            <stop offset="1" stopColor={color[1]} />
                          </linearGradient>
                        ))}
                    </defs>
                    <Bar
                      dataKey="Negative"
                      stackId="a"
                      fill={colours.primary100} radius={[10, 10, 0, 0]}>
                        {sentiment.map((entry, index) => (
                          <Cell key={`cell0-${index}`} fill={`url(#colorUv0)`} />
                        ))}
                    </Bar>
                    <Bar
                      dataKey="Neutral"
                      stackId="a"
                      fill={colours.dividergray} radius={[10, 10, 0, 0]}>
                        {sentiment.map((entry, index) => (
                          <Cell key={`cell1-${index}`} fill={`url(#colorUv1)`} />
                        ))}
                    </Bar>
                    <Bar
                      dataKey="Positive"
                      stackId="a"
                      fill={colours.green100} radius={[10, 10, 0, 0]}>
                        {sentiment.map((entry, index) => (
                          <Cell key={`cell2-${index}`} fill={`url(#colorUv2)`} />
                        ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div style={colours.vboxSmall} className="vocalysd-card">
              <div className="header-icon">
                <FeatherIcon icon="alert-triangle" />
              </div>
              <div style={{display: "inline-block", verticalAlign: "super"}} className="vocalysd-heading">Alerts</div>
              <div>
                {!conversation.trackersdetected
                  ? null
                  : conversation.trackersdetected.slice(0,7).map((z, zi) => (
                      <Chip
                        key={zi}
                        label={z.name}
                        style={{
                          background: "#E87522",
                          color: "#fff",margin:1,
                          fontFamily: "Poppins",
                        }}
                      />
                    ))}
              </div>
            </div>
          </div>
          <div className="vocalysd-card">
            <div className="header-icon">
                <FeatherIcon icon="activity" />
            </div>
            <div style={{marginBottom: 16, display: "inline-block", verticalAlign: "super"}} className="vocalysd-heading">Trackers</div>
            <div>
              {!conversation.trackersdetected
                ? null
                : conversation.trackersdetected.sort((a,b)=>b.matches.length-a.matches.length).map((a, ax) => (
                    <div
                      key={ax}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30%",
                          textAlign: "right",
                          marginRight: 10,
                          marginBottom: 5,
                          fontSize: 12,
                        }}
                      >
                        {capcase(a.name)}
                      </div>
                      <Box sx={{ color: colours.subduedgray, width: "65%" }}>
                        <BorderLinearProgressD
                          variant="determinate"
                          color="inherit"
                          value={a.matches.length}
                        />
                      </Box>
                    </div>
                  ))}
            </div>
          </div>
          <div className="vocalysd-card">
            <div
              style={{
                display: "block",
                /*flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",*/
              }}
            >
              <div className="header-icon">
                  <FeatherIcon icon="check-circle" />
              </div>
              <div style={{display: "inline-block", verticalAlign: "super"}} className="vocalysd-heading">Top questions</div>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                style={{
                    float: "right",
                  }}
                className="buttons-border-extend"
              >
                <Button
                  onClick={() => setPickedAgent(true)}
                  style={{
                    background: pickedAgent
                      ? colours.primary100
                      : colours.white,
                    boxShadow: pickedAgent
                        ? "none"
                        : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                    color: pickedAgent ? colours.white : colours.black,
                    textTransform: "none",
                    fontFamily: "Poppins",
                  }}
                >
                  Agent
                </Button>
                <Button
                  style={{
                    background: !pickedAgent
                      ? colours.primary100
                      : colours.white,
                    boxShadow: !pickedAgent
                        ? "none"
                        : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                    color: !pickedAgent ? colours.white : colours.black,
                    textTransform: "none",
                    fontFamily: "Poppins",
                  }}
                  onClick={() => setPickedAgent(false)}
                >
                  Client
                </Button>
              </ButtonGroup>
            </div>
            <div style={{ marginTop: 16 }}>
              <Table>
                <TableBody>
                  {!conversation.questions ? (
                    <TableRow />
                  ) : (
                    conversation.questions.questions
                      .filter((z) =>
                        pickedAgent ? z.from.name === "Speaker 2" : "Speaker 1"
                      )
                      .sort((a, b) => b.n - a.n)
                      .slice(0, 7)
                      .map((z, zi) => (
                        <TableRow key={zi}>
                          <TableCell
                            style={{
                              paddingTop: 3,
                              paddingBottom: 3,
                              fontFamily: "Poppins",
                            }}
                          >
                            {zi + 1 + ". " + z.text}
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div className="vocalysd-card"
                style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
              }}>
              <div>
                <div className="header-icon">
                    <FeatherIcon icon="check-circle" />
                </div>
                <div style={{display: "inline-block", verticalAlign: "super"}} className="vocalysd-heading">Avg. WPM agent vs client</div>
                
              </div>
              <div style={{ width: "100%", height: 120 }}>
                <img src={ComingSoonIcon1} alt="" style={{ width: "100%" }} />
                {/*<ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    className="linechart"
                    width="100%"
                    height={300}
                    data={
                      !conversation.analytics
                        ? []
                        : [
                            {
                              name: "Mar",
                              Agent: conversation.analytics.members
                                .find((a) => a.name === "Speaker 2" || a.name === "unknown_speaker" || a.name === "Speaker 0" || a.name === "Speaker 4" || a.name === "Speaker 6")
                                .pace.wpm.toFixed(0),
                              Client: conversation.analytics.members
                                .find((a) => a.name === "Speaker 1" || a.name === "Speaker 3" || a.name === "Speaker 5" || a.name === "Speaker 7")
                                .pace.wpm.toFixed(0),
                            },
                            {
                              name: "Mar",
                              Agent: conversation.analytics.members
                                .find((a) => a.name === "Speaker 2" || a.name === "unknown_speaker" || a.name === "Speaker 0" || a.name === "Speaker 4" || a.name === "Speaker 6")
                                .pace.wpm.toFixed(0),
                              Client: conversation.analytics.members
                                .find((a) => a.name === "Speaker 1" || a.name === "Speaker 3" || a.name === "Speaker 5" || a.name === "Speaker 7")
                                .pace.wpm.toFixed(0),
                            },
                          ]
                    }
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} />
                    <ReferenceArea y1={125} y2={155} />
                    <XAxis dataKey="name" hide={true} axisLine={{ stroke: "#DEE2F1" }} fontFamily={"Poppins"}/>
                    <YAxis
                      hide={false}
                      type="number"
                      minTickGap={0}
                      domain={[100, 180]}
                      style={{
                        fontSize: 12,
                        fontFamily: "Poppins",
                      }}
                      axisLine={{ stroke: "#DEE2F1" }}
                      fontFamily={"Poppins"}
                      interval="preserveStartEnd"
                      ticks={[125, 155]}
                    />
                    <Tooltip />
                    <Legend
                      iconType="circle"
                      style={{marginTop:"15px"}}
                      formatter={(v, e, i) => {
                        if (conversation.analytics) {
                          let agent = conversation.analytics.members
                            .find((a) => a.name === "Speaker 2" || a.name === "unknown_speaker" || a.name === "Speaker 0" || a.name === "Speaker 4" || a.name === "Speaker 6")
                            .pace.wpm.toFixed(0);
                          let client = conversation.analytics.members
                            .find((a) => a.name === "Speaker 1" || a.name === "Speaker 3" || a.name === "Speaker 5" || a.name === "Speaker 7")
                            .pace.wpm.toFixed(0);
                          if (v === "Agent") return <span style={{color:"#000"}}>{"Agent " + agent}</span>;
                          if (v === "Client") return <span style={{color:"#000"}}>{"Client " + client}</span>;
                        } else return v;
                      }}
                    />

                    <Line
                      type="monotone"
                      dataKey="Agent"
                      stroke={colours.primary100}
                    />
                    <Line
                      type="monotone"
                      dataKey="Client"
                      stroke={colours.purple100}
                    />
                  </LineChart>
                </ResponsiveContainer>*/}
              </div>
            </div>
            <div className="vocalysd-card"
                style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
              }}>
              <div>
                <div className="header-icon">
                    <FeatherIcon icon="pie-chart" />
                </div>
                <div style={{display: "inline-block", verticalAlign: "super"}} className="vocalysd-heading">Speaker analytics</div>
                
              </div>
              <div style={{ width: "100%", height: 120 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width="100%" height={300} className="piechart">
                    <defs>
                        {barColors2.map((color, index) => (
                          <linearGradient
                            id={`color2Uv${index}`}
                            x1="0"
                            y1="0"
                            x2="100%"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor={color[0]} />
                            <stop offset="1" stopColor={color[1]} />
                          </linearGradient>
                        ))}
                    </defs>
                    <Pie
                      dataKey="value"
                      startAngle={360}
                      endAngle={0}
                      data={
                        !conversation.analytics
                          ? []
                          : [
                              {
                                name: "Agent",
                                value: conversation.analytics.members.find(
                                  (a) => a.name === "Speaker 2" || a.name === "unknown_speaker" || a.name === "Speaker 0" || a.name === "Speaker 4" || a.name === "Speaker 6"
                                ).talkTime.seconds,
                              },
                              {
                                name: "Client",
                                value: conversation.analytics.members.find(
                                  (a) => a.name === "Speaker 1" || a.name === "Speaker 3" || a.name === "Speaker 5" || a.name === "Speaker 7"
                                ).talkTime.seconds,
                              },
                              {
                                name: "Silence",
                                value: conversation.analytics.metrics.find(
                                  (a) => a.type === "total_silence"
                                ).seconds,
                              },
                            ]
                      }
                      cx="50%"
                      cy="50%"
                      outerRadius={50}
                      fill="#8884d8"
                    >
                      {!conversation.analytics
                        ? []
                        : [
                            {
                              name: "Agent",
                              value: conversation.analytics.members.find(
                                (a) => a.name === "Speaker 2" || a.name === "unknown_speaker" || a.name === "Speaker 0" || a.name === "Speaker 4" || a.name === "Speaker 6"
                              ).talkTime.seconds,
                            },
                            {
                              name: "Client",
                              value: conversation.analytics.members.find(
                                (a) => a.name === "Speaker 1" || a.name === "Speaker 3" || a.name === "Speaker 5" || a.name === "Speaker 7"
                              ).talkTime.seconds,
                            },
                            {
                              name: "Silence",
                              value: conversation.analytics.metrics.find(
                                (a) => a.type === "total_silence"
                              ).seconds,
                            },
                          ].map((entry, index) => (
                            <Cell
                              key={`cell5-${index}`}
                              fill={`url(#color2Uv${index})`}
                            />
                          ))}
                    </Pie>

                    <Legend
                      align="right"
                      layout="vertical"
                      verticalAlign="middle"
                      width="80px"
                      iconType="circle"
                      formatter={(v, e, i) => {
                        return (
                          v + " " + (e.payload.percent * 100).toFixed(0) + "%"
                        );
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="vocalysd-card">
            <div className="header-icon">
                <FeatherIcon icon="message-circle" />
            </div>
            <div style={{marginBottom: 16, display: "inline-block", verticalAlign: "super"}} className="vocalysd-heading">Topics</div>

            <div>
              {!conversation.topics
                ? null
                : conversation.topics.topics
                    .filter((r) => r.text)
                    .sort((f, g) => g.messageIds.length - f.messageIds.length)
                    .map((a, ax) => (
                      <div
                        key={ax}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "30%",
                            textAlign: "right",
                            marginRight: 10,
                            fontSize: 12,
                          }}
                        >
                          {capcase(a.text)}
                        </div>
                        <Box sx={{ color: colours.subduedgray, width: "65%" }}>
                          {a.score > 0.3 ? (
                            <BorderLinearProgressP
                              variant="determinate"
                              color="inherit"
                              className="bar-positive"
                              value={a.messageIds.length}
                            />
                          ) : (a.score < 0.05 ? (
                            <BorderLinearProgressP
                              variant="determinate"
                              color="inherit"
                              className="bar-negative"
                              value={a.messageIds.length}
                            />
                          ) : (
                            <BorderLinearProgress
                              variant="determinate"
                              color="inherit"
                              className="bar-neutral"
                              value={a.messageIds.length}
                            />
                          ))}
                        </Box>
                      </div>
                    ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={colours.vboxSmallSpacer}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConversationInsight;
