import React from "react";
import { useDropzone } from "react-dropzone";
import WaveSurfer from "wavesurfer.js";

const FileDropZone = ({ files, setFiles, selectedTab }) => {
  const waveformRef = React.useRef(null);
  const wavesurferRef = React.useRef(null);

  const onDrop = (acceptedFiles) => {
    const validFiles = acceptedFiles.filter((file) => {
      const validTypes = getAcceptedFileTypes();
      return validTypes.some((type) => file.type.match(type));
    });

    setFiles(
      validFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    if (acceptedFiles.length !== validFiles.length) {
      alert("Some files were rejected because they do not match the required file type.");
    }
  };

  const getAcceptedFileTypes = () => {
    switch (selectedTab) {
      case "Upload Audio":
        return ["audio/mpeg", "audio/wav", "audio/mp4", "audio/*"];
      default:
        return ["*/*"];
    }
  };

  const getDropzoneText = () => {
    switch (selectedTab) {
      case "Upload Audio":
        return "Drag and drop files here, or click to select files";
      default:
        return "Drag and drop files here, or click to select files";
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: getAcceptedFileTypes(),
  });

  // Initialize WaveSurfer when we have a file
  React.useEffect(() => {
    if (files.length > 0 && waveformRef.current) {
      // Clean up existing instance if any
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }

      const file = files[0]; // Single file scenario
      if (file.type && file.type.startsWith("audio/")) {
        wavesurferRef.current = WaveSurfer.create({
          container: waveformRef.current,
          waveColor: "#999",
          progressColor: "#555",
          cursorColor: "#333",
          height: 80,
          barWidth: 2,
        });

        wavesurferRef.current.load(file.preview);
      }
    }
  }, [files]);

  return (
    <div
      {...getRootProps()}
      style={{
        border: "2px dashed #ccc",
        borderRadius: "8px",
        padding: "20px",
        textAlign: "center",
        cursor: "pointer",
        position: "relative",
        width: "400px",     // Fixed width
        margin: "0 auto",   // Center horizontally within parent
      }}
    >
      <input {...getInputProps()} />
      {files.length > 0 ? (
        <div style={{ position: "relative", width: "100%" }}>
          {/* Waveform Container */}
          <div ref={waveformRef} style={{ width: "100%", pointerEvents: "none" }}></div>
          {/* Overlay with filename centered */}
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontWeight: "bold",
              backgroundColor: "rgba(255,255,255,0.7)",
              padding: "5px 10px",
              borderRadius: "4px",
            }}
          >
            {files[0].name}
          </div>
        </div>
      ) : (
        <div style={{ color: "#000" }}>{getDropzoneText()}</div>
      )}
    </div>
  );
};

export default FileDropZone;
