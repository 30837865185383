import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { colours, tabselected, tabselectedNopoint } from "../../consts.js";
import { useNavigate } from "react-router-dom";
import Utils from "../../utils.js";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import FeatherIcon from "feather-icons-react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";

function AdminUser() {
  let navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [companyList, setCompanyList] = React.useState([]);
  const [newCampDiag, setNewCampDiag] = React.useState(false);
  const [selectedTab] = React.useState("User setup");
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);

  async function countUsers() {
    let users = [];
    let dta = await Utils.GenAdmFunc("listUsers", {
      AttributesToGet: ["sub", "email"],
      UserPoolId: "STRING_VALUE",
      Limit: 60,
    });

    users = users.concat(dta.Users);
    while (dta.PaginationToken !== undefined) {
      dta = await Utils.GenAdmFunc("listUsers", {
        AttributesToGet: ["sub", "email"],
        UserPoolId: "STRING_VALUE",
        PaginationToken: dta.PaginationToken,
        Limit: 60,
      });
      users = users.concat(dta.Users);
    }

    users = users.map((z) => z.Attributes);
    async function getgroupsforuser(id) {
      let user = id.find((z) => z.Name === "sub").Value;
      let email = id.find((z) => z.Name === "email").Value;
      let groups = await Utils.GenAdmFunc("adminListGroupsForUser", {
        Username: user,
        UserPoolId: "STRING_VALUE",
      });
      let admin = false;
      if (groups.find((z) => z === "admin")) {
        admin = true;
        groups = groups.filter((z) => z !== "admin");
      }
      if (groups.length === 1) groups = groups[0];
      return { user, email, admin, group: groups };
    }

    let newvals = await Promise.all(users.map(getgroupsforuser));
    return newvals;
  }

  async function getCompanyData() {
    Utils.sendProgressBar(true);
    setSpinner(true);
    let userData = await Utils.getUserGroupFromCognito();
    let user = userData; // Access the user object
    if (user.isAdmin === null || user.isAdmin === false) navigate("/");
    else {
      let companies = await Utils.genericList("listCompanies", { limit: 1000 });
      companies = companies.data.listCompanies.items;
      setCompanyList(companies);
      let users = await countUsers();
      setData(users);
    }
    setSpinner(false);
  }

  React.useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        await getCompanyData(); // Your async data fetching function
        setDataLoaded(true); // Mark data as loaded
      };
      fetchData();
    }

    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, [dataLoaded]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <NewCampaignD1
        open={newCampDiag}
        companyList={companyList}
        onClose={async (inp) => {
          if (inp === null) setNewCampDiag(false);
          else {
            Utils.sendProgressBar(true);
            let usr1 = await Utils.GenAdmFunc("adminCreateUser", {
              UserPoolId: "STRING_VALUE",
              Username: inp.email,
              DesiredDeliveryMediums: ["EMAIL"],
            });
            await Utils.GenAdmFunc("adminUpdateUserAttributes", {
              UserPoolId: "STRING_VALUE",
              Username: inp.email,
              UserAttributes: [
                {
                  Name: "email_verified",
                  Value: "true",
                },
                // other user attributes like phone_number or email themselves, etc
              ],
            });
            await Utils.genericMutation("createUser", {
              id: usr1.User.Username,
            });
            await Utils.GenAdmFunc("adminAddUserToGroup", {
              GroupName: inp.company,
              Username: usr1.User.Username,
              UserPoolId: "STRING_VALUE",
            });
            if (inp.admin) {
              await Utils.GenAdmFunc("adminAddUserToGroup", {
                GroupName: "admin",
                Username: usr1.User.Username,
                UserPoolId: "STRING_VALUE",
              });
            }

            getCompanyData();
            setNewCampDiag(false);
          }
        }}
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FeatherIcon
          icon="award"
          style={{ marginLeft: 20, marginBottom: 10 }}
        />
        <div
          style={{ marginLeft: 5 }}
          onClick={() => {
            navigate("/admin");
          }}
        >
          Admin /
        </div>
        <FeatherIcon icon="list" style={{ marginLeft: 5, marginBottom: 10 }} />
        <div className="menu-item-selected">Users</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: colours.dividergraybottomborder,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <div
            style={tabselected(selectedTab === "Companies")}
            onClick={() => {
              navigate("/admin");
            }}
          >
            Companies
          </div>
          <div
            style={tabselected(selectedTab === "Tracker admin")}
            onClick={() => {
              navigate("/trackeradmin");
            }}
          >
            Tracker admin
          </div>
          <div
            style={tabselected(selectedTab === "User setup")}
            onClick={() => {
              navigate("/adminusers");
            }}
          >
            User setup
          </div>
        </div>
      </div>
      <div className="vocalysed-home">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: 30,
              marginTop: 10,
            }}
          ></div>
          <div className="vocalysd-card vocalysd-table">
            <div style={{ alignItems: "end" }}>
              <Button
                style={{
                  textTransform: "none",
                  float: "right",
                  borderRadius: 20,
                  marginRight: 20,
                  marginTop: 10,
                  background: colours.primary100,
                  fontFamily: "Poppins",
                }}
                variant="contained"
                onClick={() => setNewCampDiag(true)}
              >
                <FeatherIcon
                  icon="plus-circle"
                  style={{ color: colours.white, marginRight: 8 }}
                />
                Invite user
              </Button>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      User
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Admin
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Company
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {spinner ? (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <CircularProgress style={{ color: colours.primary100 }} />
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ) : (
                  data.map((z, zidx) => (
                    <TableRow key={zidx} style={{}}>
                      <TableCell style={{ width: "25%" }}>
                        <div
                          style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                        >
                          {z.email}
                        </div>
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            fontFamily: "Poppins",
                          }}
                        >
                          <Switch
                            checked={z.admin}
                            onChange={async (c) => {
                              if (c.target.checked === false)
                                Utils.sendSnackBar("Cannot remove admin");
                              else {
                                await Utils.GenAdmFunc("adminAddUserToGroup", {
                                  GroupName: "admin",
                                  Username: z.user,
                                  UserPoolId: "STRING_VALUE",
                                });
                                getCompanyData();
                              }
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            fontFamily: "Poppins",
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Company
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={z.group}
                              label="Company"
                              InputLabelProps={{
                                style: { color: "#84858a" },
                              }}
                              onChange={async (c) => {
                                Utils.sendProgressBar(true);
                                // console.log(c.target.value);
                                // console.log({ z });
                                if (z.group.length !== 0)
                                  await Utils.GenAdmFunc(
                                    "adminRemoveUserFromGroup",
                                    {
                                      GroupName: z.group,
                                      Username: z.user,
                                      UserPoolId: "STRING_VALUE",
                                    }
                                  );
                                await Utils.GenAdmFunc("adminAddUserToGroup", {
                                  GroupName: c.target.value,
                                  Username: z.user,
                                  UserPoolId: "STRING_VALUE",
                                });
                                await Utils.refreshUserGroups();
                                getCompanyData();
                              }}
                            >
                              {companyList.map((a, aid) => (
                                <MenuItem value={a.internalname} key={aid}>
                                  {a.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUser;

function NewCampaignD1({ open, onClose, companyList }) {
  const [company, setCompany] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [admin, setAdmin] = React.useState(false);

  React.useEffect(() => {
    // console.log(open)
    setCompany("");
    setEmail("");
    setAdmin(false);
  }, [open]);

  return (
    <Dialog
      disableAutoFocus
      onClose={() => onClose(null)}
      onBackdropClick={() => {
        onClose(null);
      }}
      open={open}
      style={{
        padding: 0,

        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 30,
      }}
    >
      <DialogTitle style={{ padding: 0, minWidth: "600px", width: "600px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,

              fontFamily: "Poppins",
            }}
          >
            <div>Invite user</div>
            <FeatherIcon
              icon="x"
              style={{ color: "#ddd", cursor: "pointer" }}
              onClick={() => onClose(null)}
            />
          </div>
          <Divider style={{}} />
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            jus: "space-between",
            alignItems: "center",
            padding: 20,
            fontFamily: "Poppins",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
          <TextField
            id="outlined-basic"
            variant="outlined"
            style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
            label="Email"
            InputLabelProps={{
              style: { color: "#84858a" },
            }}
            value={email}
            onChange={(c) => {
              setEmail(c.target.value);
            }}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Company</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={company || ""}
              label="Company"
              InputLabelProps={{
                style: { color: "#84858a" },
              }}
              onChange={async (c) => {
                setCompany(c.target.value);
              }}
            >
              {companyList.map((a, aid) => (
                <MenuItem value={a.internalname} key={aid}>
                  {a.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div
            style={{
              marginTop: 16,
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            Admin
            <Switch
              checked={admin}
              onChange={async (c) => {
                setAdmin(c.target.checked);
              }}
            />
          </div>
        </div>
        <Divider style={{}} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 20,
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              float: "right",
              marginRight: 5,
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.white,
              color: colours.primary100,
              border: "1px solid #ddd",
              boxShadow: "none",
            }}
            variant="contained"
            onClick={() => onClose(null)}
          >
            Cancel
          </Button>
          <Button
            style={{
              textTransform: "none",
              float: "right",
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.primary100,
            }}
            variant="contained"
            onClick={() => {
              onClose({
                email,
                admin,
                company,
              });
            }}
          >
            Invite
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
