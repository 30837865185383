import React from "react";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
//import InputLabel from "@mui/material/InputLabel";
//import MenuItem from "@mui/material/MenuItem";
//import FormControl from "@mui/material/FormControl";
//import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import { colours } from "../../consts.js";
import { useNavigate } from "react-router-dom";
//import InputAdornment from "@mui/material/InputAdornment";
import Utils from "../../utils.js";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import FeatherIcon from "feather-icons-react";
import { format } from "date-fns";

function Trackers() {
  let navigate = useNavigate();
  //const [age] = React.useState("");
  const [data, setData] = React.useState([]);
  const [companyDT, setCompanyDT] = React.useState({});
  const [newCampDiag, setNewCampDiag] = React.useState(false);
  const [isAdmin, setIsadmin] = React.useState(false);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);

  async function getCompanyData() {
    Utils.sendProgressBar(true);
    setSpinner(true);
    let userData = await Utils.getUserGroupFromCognito();
    let user = userData.filter((z) => z !== "admin");

    let company = await Utils.genericList("listCompanies", {
      filter: { internalname: { eq: user[0] } },
    });
    company = company.data.listCompanies.items[0].id;
    company = await Utils.genericGet("getCompany", company);
    company = company.data.getCompany;
    company.trackers = JSON.parse(company.trackers);
    company.campaigns.items.forEach(
      (z) => (z.trackers = JSON.parse(z.trackers))
    );
    setCompanyDT(company);
    let trackers = [];
    company.trackers.forEach((z) => {
      let found = trackers.find((a) => a.name === z.name);
      if (!found) {
        z.campaigns = [];
        trackers.push(z);
      }
    });
    company.campaigns.items.forEach((z) => {
      z.trackers.forEach((b) => {
        let found = trackers.find((a) => a.name === b.name);
        if (!found) {
          b.campaigns = [];
          trackers.push(b);
        } else {
          found.campaigns.push(z.name);
        }
      });
    });
    setData(trackers);
    setSpinner(false);
  }

  function navigateToTracker(newTrackerName) {
    let today = new Date(Date.now());
    today = today.toISOString().substr(0, 10);

    navigate("/tracker", {
      state: {
        tracker: { name: newTrackerName, created: today, phrases: [] },
        company: companyDT,
      },
    });
  }

  React.useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        await getCompanyData(); // Your async data fetching function
        setDataLoaded(true); // Mark data as loaded
      };
      fetchData();
    }

    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, [dataLoaded]);

  return (
    <div className="vocalysed-home">
      <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
        <FeatherIcon
          icon="award"
          style={{ marginLeft: 20, marginBottom: 10 }}
        />
        <div className="menu-item-selected"> Trackers </div>
      </div>
      <NewCampaignD1
        open={newCampDiag}
        onClose={async (inp) => {
          if (inp === null) setNewCampDiag(false);
          else {
            Utils.sendProgressBar(true);
            let datac = Object.assign({}, companyDT);
            let today = new Date(Date.now());
            today = today.toISOString().substr(0, 10);
            datac.trackers.push({ name: inp, created: today, phrases: [] });
            await Utils.genericMutation("updateCompany", {
              id: datac.id,
              trackers: JSON.stringify(datac.trackers),
            });
            await getCompanyData();
            navigateToTracker(inp);
          }
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          background: "#f8f9fd",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: 30,
              marginTop: 10,
            }}
          >
            <div></div>
            <Button
              style={{
                textTransform: "none",
                float: "right",
                borderRadius: 20,
                marginRight: 20,
                /*marginTop: 16,*/
                marginBottom: 20,
                background: colours.primary100,
                fontFamily: "Poppins",
              }}
              variant="contained"
              onClick={() => setNewCampDiag(true)}
            >
              <FeatherIcon
                icon="plus-circle"
                style={{ color: colours.white, marginRight: 8 }}
              />
              Create new tracker
            </Button>
          </div>
          <div className="vocalysd-card vocalysd-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Tracker name
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Phrases
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Campaigns
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Created
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Set alert
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Edit
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {spinner ? (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell align="center">

                        <CircularProgress
                          style={{ color: colours.primary100 }}
                        />
                     
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ) : (
                  data.map((z, zidx) => (
                    <TableRow key={zidx} style={{}}>
                      <TableCell>
                        <div
                          style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                        >
                          {z.name}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            fontFamily: "Poppins",
                          }}
                        >
                          {z.phrases.length}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ fontFamily: "Poppins" }}>
                          {z.campaigns.map((a, aidx) => (
                            <Chip
                              label={a}
                              key={aidx}
                              style={{
                                marginRight: 2,
                                marginBottom: 3,
                                backgroundColor: "#DEE2F1",
                              }}
                            />
                          ))}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div style={{ fontFamily: "Poppins" }}>
                          {format(new Date(z.created), "d/MM/yyyy")}
                        </div>
                        <div style={{ fontFamily: "Poppins" }}>{z.company}</div>
                      </TableCell>
                      <TableCell align="center">
                        <div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              Utils.sendProgressBar(true);
                              let d = Object.assign([], data);
                              d[zidx].alert = !d[zidx].alert;
                              setData(d);
                              let company = await Utils.genericGet(
                                "getCompany",
                                companyDT.id
                              );
                              company = company.data.getCompany;
                              company.trackers = JSON.parse(company.trackers);
                              let found = company.trackers.find(
                                (a) => a.name === z.name
                              );
                              if (found) found.alert = d[zidx].alert;
                              await Utils.genericMutation("updateCompany", {
                                id: companyDT.id,
                                trackers: JSON.stringify(company.trackers),
                              });
                            }}
                          >
                            {z.alert ? (
                              <FeatherIcon
                                icon="bell"
                                style={{ color: colours.green100 }}
                              />
                            ) : (
                              <FeatherIcon
                                icon="bell-off"
                                style={{ color: "#aaaaaa6e" }}
                              />
                            )}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div
                          onClick={() =>
                            navigate("/tracker", {
                              state: {
                                tracker: z,
                                company: companyDT,
                              },
                            })
                          }
                        >
                          <FeatherIcon
                            icon="edit-2"
                            style={{ color: "#aaa", cursor: "pointer" }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trackers;

function NewCampaignD1({ open, onClose }) {
  const [tracker, setTracker] = React.useState("");

  return (
    <Dialog
      disableAutoFocus
      onClose={() => onClose(null)}
      onBackdropClick={() => {
        onClose(null);
      }}
      open={open}
      style={{
        padding: 0,

        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 30,
      }}
    >
      <DialogTitle style={{ padding: 0, minWidth: "600px", width: "600px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,

              fontFamily: "Poppins",
            }}
          >
            <div>New tracker</div>

            <FeatherIcon
              icon="x"
              style={{ color: "#ddd", cursor: "pointer" }}
              onClick={() => onClose(null)}
            />
          </div>
          <Divider style={{}} />
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            jus: "space-between",
            alignItems: "center",
            padding: 20,
            fontFamily: "Poppins",
          }}
        >
          <TextField
            id="outlined-basic"
            variant="outlined"
            style={{ width: "100%", marginTop: 20 }}
            label="Tracker"
            InputLabelProps={{
              style: { color: "#84858a" },
            }}
            onChange={(c) => {
              setTracker(c.target.value);
            }}
            value={tracker}
          />
        </div>
        <Divider style={{}} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 20,
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              float: "right",
              marginRight: 5,
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.white,
              color: colours.primary100,
              border: "1px solid #ddd",
              boxShadow: "none",
            }}
            variant="contained"
            onClick={() => onClose(null)}
          >
            Cancel
          </Button>
          <Button
            style={{
              textTransform: "none",
              float: "right",
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.primary100,
            }}
            variant="contained"
            onClick={() => {
              onClose(tracker);
            }}
          >
            Done
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
