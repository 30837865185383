// Header.js
import React from 'react';
import { Button, Menu, MenuItem, Badge } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

function TT({ info }) {
    return (
        <HtmlTooltip
            title={
                <React.Fragment>
                    <Typography color="inherit" style={{ fontFamily: "Poppins" }}>
                        {info}
                    </Typography>
                </React.Fragment>
            }
        >
            <IconButton style={{ marginLeft: 30, marginTop: "-8px" }}>
                <FeatherIcon icon="info" style={{ color: "#e7a500", width: 20, height: 20 }} />
            </IconButton>
        </HtmlTooltip>
    );
}

const CustomHeader = ({ history, pages, user, companyDT, open, anchorEl, handleClick, handleClose, open2, anchorEl2, handleClick2, handleClose2, navigate, colours, username }) => {
    return (
        <Toolbar
            style={{
                display: "flex",
                background: "#fff",
                color: "#000",
                height: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <div style={{ fontSize: 32 }}>
                {history.pathname === "/organisation"
                    ? "Organisation"
                    : pages.find((z) => z.path === history.pathname).name}
                {pages.find((z) => z.path === history.pathname).tooltip ? (
                    <TT info={pages.find((z) => z.path === history.pathname).tooltip} />
                ) : null}
            </div>
            <div>
                {user ? (
                    <div>
                        <div
                            style={{
                                textTransform: "none",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? "basic-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleClick}
                                    variant="transparent"
                                >
                                    {companyDT.length > 0 ? (
                                        <Badge
                                            badgeContent={companyDT.length}
                                            sx={{
                                                "& .MuiBadge-colorPrimary": {
                                                    background: colours.critical100,
                                                },
                                            }}
                                            color="primary"
                                        >
                                            <FeatherIcon icon="bell" />
                                        </Badge>
                                    ) : (
                                        <FeatherIcon icon="bell" />
                                    )}
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontFamily: "Poppins",
                                                padding: 16,
                                                fontSize: 22,
                                            }}
                                        >
                                            Alerts
                                        </div>
                                        <div>
                                            <Button
                                                style={{
                                                    textTransform: "none",
                                                    float: "right",
                                                    marginRight: 5,
                                                    borderRadius: 20,
                                                    fontWeight: "bold",
                                                    fontFamily: "Poppins",
                                                    background: colours.white,
                                                    color: colours.primary100,
                                                    border: "1px solid #ddd",
                                                    boxShadow: "none",
                                                }}
                                                variant="contained"
                                                onClick={() => {
                                                    navigate("/alerts");
                                                    handleClose();
                                                }}
                                            >
                                                Go to alerts page
                                            </Button>
                                        </div>
                                    </div>
                                    {companyDT.map((z, zidx) => (
                                        <MenuItem
                                            onClick={() => {
                                                navigate("/conversation?id=" + z.conversation);
                                            }}
                                            key={zidx}
                                            style={{
                                                width: 400,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                borderBottom: "1px solid #fff",
                                                background: colours.basegray,
                                                padding: 16,
                                            }}
                                        >
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div style={{ fontSize: 16 }}>{z.name}</div>
                                                <div style={{ fontSize: 12 }}>{z.campaign}</div>
                                            </div>
                                            <div
                                                style={{
                                                    textAlign: "right",
                                                    display: "flex",
                                                    alignSelf: "center",
                                                    fontSize: 10,
                                                }}
                                            >
                                                {moment(z.createdAt).fromNow()}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>

                            <div>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? "basic-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleClick2}
                                    variant="transparent"
                                    style={{ textTransform: "none", fontSize: 16 }}
                                >
                                    {username ? username : user.attributes.email}
                                    <FeatherIcon icon="chevron-down" />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl2}
                                    open={open2}
                                    onClose={handleClose2}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: 16,
                                            width: 150,
                                        }}
                                    >
                                        <div style={{ fontSize: 16 }}>
                                            {username ? username : null}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 12,
                                                color: colours.subduedgray,
                                                fontFamily: "Poppins",
                                            }}
                                        >
                                            {user.attributes.email}
                                        </div>
                                    </div>

                                    <MenuItem
                                        onClick={() => {
                                            navigate("/profile");
                                        }}
                                        style={{
                                            padding: 16,
                                            display: "flex",
                                            flexDirection: "row",
                                            borderBottom: "1px solid #fff",
                                            background: colours.basegray,
                                        }}
                                    >
                                        <FeatherIcon icon="user" style={{ marginRight: 16 }} />
                                        Profile
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            Auth.signOut();
                                        }}
                                        style={{
                                            padding: 16,
                                            display: "flex",
                                            flexDirection: "row",
                                            borderBottom: "1px solid #fff",
                                            background: colours.basegray,
                                        }}
                                    >
                                        <FeatherIcon icon="log-out" style={{ marginRight: 16 }} />
                                        Log out
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => Auth.federatedSignIn()}
                        style={{
                            background: colours.primary100,
                            color: colours.white,
                            fontFamily: "Poppins",
                            textTransform: "none",
                        }}
                    >
                        Login
                    </Button>
                )}
            </div>
        </Toolbar>
    );
};

export default CustomHeader;