import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/home/home";
import Admin from "../pages/admin/admin";
import Profile from "../pages/profile/profile";
import Campaigns from "../pages/campaign/campaigns";
import Campaign from "../pages/campaign/campaign";
import Conversation from "../pages/conversation/conversation";
import Login from "../pages/login/login";
import Organisation from "../pages/organisation/organisation";
import OrganisationSetup from "../pages/organisation/organisation-setup";
import Playlists from "../pages/playlists/playlists";
import Privacy from "../pages/terms-and-privacy/privacy";
import Settings from "../pages/settings";
import Terms from "../pages/terms-and-privacy/terms";
import AdminUsers from "../pages/admin/admin-users";
import Trackers from "../pages/tracker/trackers";
import TrackerAdmin from "../pages/tracker/tracker-admin";
import Alerts from "../pages/alerts/alerts";
import Tracker from "../pages/tracker/tracker";

const AppRoutes = ({ isUser }) => {
    return (
        <Routes>
            {isUser ? (
                <Route path="/" exact element={<Home />} />
            ) : (
                <Route path="/" exact element={<Login />} />
            )}
            <Route path="/admin" element={<Admin />} />
            <Route path="/adminusers" element={<AdminUsers />} />
            <Route path="/campaigns" element={<Campaigns />} />
            <Route path="/campaign" element={<Campaign />} />
            <Route path="/conversation" element={<Conversation />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/organisation" element={<Organisation />} />
            <Route path="/organisationsetup" element={<OrganisationSetup />} />
            <Route path="/playlists" element={<Playlists />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/trackers" element={<Trackers />} />
            <Route path="/trackeradmin" element={<TrackerAdmin />} />
            <Route path="/alerts" element={<Alerts />} />
            <Route path="/tracker" element={<Tracker />} />
            <Route path="/login" element={<Login />} />
            <Route path="/terms" element={<Terms />} />
        </Routes>
    );
};

export default AppRoutes;
