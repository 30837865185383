import React from "react";

function Login() {
  //Auth.federatedSignIn()
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height:"80vh",
        justifyContent: "center",
      }}
    >
      Please log in
    </div>
  );
}

export default Login;
