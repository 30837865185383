import React from "react";
import { colours, tabselected } from "../../consts.js";
import CampaignInsights from "./campaign-insights";
import CampaignConversations from "./campaign-conversations";
import CampaignArchive from "./campaign-archived";
import { useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Utils from "../../utils";
import UploadDialogue from "../../components/upload/upload-dialogue";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

function Campaign() {
  let location = useLocation();
  let navigate = useNavigate();
  let camid = location.search.replace("?id=", "");

  const [uploadDiag, setUploadDiag] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState("Campaign insights");
  const [campaignName, setCampaignName] = React.useState(camid);
  const [campaigndata, setCampaignData] = React.useState({
    name: "",
    type: "",
    company: { name: "" },
  });

  const [open, setOpen] = React.useState(false);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);

  const handleTabClick = (tabName) => {
    setOpen(true); // Open the dialog when the tab is clicked
  };

  async function getCampaignData() {
    setSpinner(true);
    Utils.sendProgressBar(true);
    let camids = location.search.replace("?id=", "");
    let t = await Utils.genericGet("getCampaign", camids);
    setCampaignName(t.data.getCampaign.name);
    setCampaignData(t.data.getCampaign);
    setSpinner(false);
    Utils.sendProgressBar(false);
  }

  React.useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        await getCampaignData(); // Your async data fetching function
        setDataLoaded(true); // Mark data as loaded
      };
      fetchData();
    }

    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, [dataLoaded]);

  function returnToCampaigns() {
    navigate("/campaigns");
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {spinner && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <CircularProgress style={{ color: colours.primary100 }} />
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
        <FeatherIcon
          icon="award"
          style={{ marginLeft: 20, marginBottom: 10 }}
        />
        <div style={{ marginLeft: 5, cursor: "pointer" }}>
          <div onClick={() => returnToCampaigns()}>Campaigns /</div>
        </div>
        <FeatherIcon icon="list" style={{ marginLeft: 5, marginBottom: 10 }} />
        <div className="menu-item-selected">{campaignName}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: colours.dividergraybottomborder,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
        >
          <div
            style={tabselected(selectedTab === "Campaign insights")}
            onClick={() => setSelectedTab("Campaign insights")}
          >
            Campaign insights
          </div>
          <div
            style={tabselected(selectedTab === "Processed conversations")}
            onClick={() => setSelectedTab("Processed conversations")}
          >
            Processed conversations
          </div>
          <div
            style={tabselected(selectedTab === "Archived")}
            onClick={() => setSelectedTab("Archived")}
          >
            Archived
          </div>
        </div>
        <div style={{ paddingLeft: "800px", paddingBottom: "10px" }}>
          <Button
            style={{
              textTransform: "none",
              float: "right",
              borderRadius: 20,
              marginRight: 20,
              marginTop: 10,
              background: colours.primary100,
              fontFamily: "Poppins",
            }}
            variant="contained"
            onClick={() => handleTabClick("Upload Conversation")}
          >
            <FeatherIcon
              icon="upload"
              style={{ color: colours.white, marginRight: 8 }}
            />
            Upload 
          </Button>
          <UploadDialogue
              open={open}
              onClose={() => setOpen(false)} // Close the dialog when onClose is triggered
              campaigndata={campaigndata}
            />
        </div>
      </div>

      {selectedTab === "Campaign insights" && <CampaignInsights />}
      {selectedTab === "Processed conversations" && <CampaignConversations />}
      {selectedTab === "Archived" && <CampaignArchive />}
    </div>
  );
}

export default Campaign;
