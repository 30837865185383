import React from "react";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { colours, chipstyle, tabselected } from "../../consts.js";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Utils from "../../utils";
import enzaLocale from "date-fns/locale/en-ZA";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from 'date-fns';
import SearchBar from "../../components/search/searchbar.js";

function Campaigns() {
    let navigate = useNavigate();
    const [value, setValue] = React.useState(null);
    const [newCampDiag, setNewCampDiag] = React.useState(false);
    const [editcamp, setEditcamp] = React.useState(false);
    const [editcampdata, setEditcampdata] = React.useState({});
    const [spinner, setSpinner] = React.useState(false);
    const [newCampDiag2, setNewCampDiag2] = React.useState(false);
    const [diag2input, setDiag2input] = React.useState(null);
    const [selectedTab, setSelectedTab] = React.useState("All");
    const [age] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [trackers, setTrackers] = React.useState([]);
    const [companydata, setCompanyData] = React.useState({});
    const [searchValue, setSearchValue] = React.useState("");
    const [allCampaigns, setAllCampaigns] = React.useState([]);
    const [initialCampaigns, setInitialCampaigns] = React.useState([]);
    const [isAdmin, setIsadmin] = React.useState(false);
    const [dataLoaded, setDataLoaded] = React.useState(false);

    const filterData = searchValue => {

        if(searchValue === "") {
            return initialCampaigns;
        } else {
            return initialCampaigns.filter(z => z.name.toLowerCase().includes(searchValue.toLowerCase()) || z.company.name.toLowerCase().includes(searchValue.toLowerCase()))
        }
    }

    async function getCompanyName(nospin) {
        Utils.sendProgressBar(true);
        if(!nospin) setSpinner(true);
        let userData = await Utils.getUserGroupFromCognito();
        let user = userData.filter((z) => z !== "admin");

    
        
        setCompanyName(user);
        let company = await Utils.genericList("listCompanies", {
            filter: { internalname: { eq: user[0] } },
        });
        company = company.data.listCompanies.items[0].id;
        company = await Utils.genericGet("getCompany", company);
        company = company.data.getCompany;
        setCompanyData(company);
        company.trackers = JSON.parse(company.trackers);
        let newtrackers = company.trackers.filter((l) => l.phrases.length > 0);
        setTrackers(newtrackers);
        let campaigns = await Utils.genericList("listCampaigns");

        setAllCampaigns(campaigns.data.listCampaigns.items);
        setInitialCampaigns(campaigns.data.listCampaigns.items);
        setSpinner(false);
    
    }

    React.useEffect(() => {
        if (!dataLoaded) {
          const fetchData = async () => {
            await getCompanyName(); // Your async data fetching function
            setDataLoaded(true); // Mark data as loaded
          };
          fetchData();
        }
    
        window.scrollTo(0, 0); // Scroll to top when component mounts
    
      }, [dataLoaded]);

    React.useEffect(() => {
        const filteredData = filterData(searchValue);
        setAllCampaigns(filteredData);
    }, [searchValue]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingBottom: "10px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingBottom: "10px",
                    borderBottom: colours.dividergraybottomborder,
                }}
            >
                <div style={{ display: "flex", flexDirection: "row", marginBottom: 20 }}>
                    <FeatherIcon
                        icon="award"
                        style={{ marginLeft: 20, marginBottom: 10 }}
                    />
                    <div className="menu-item-selected"> Campaigns</div>
                </div>

                <NewCampaignD1
                    open={newCampDiag}
                    companydata={companydata}
                    onClose={(inp) => {
                        if(inp === null) setNewCampDiag(false);
                        else {
                            inp.trackers = trackers;
                            setDiag2input(inp);
                            setNewCampDiag(false);
                            if(inp !== null) setNewCampDiag2(true);
                        }
                    }}
                />
                <NewCampaignD3
                    open={editcamp}
                    companydata={editcampdata}
                    intrackersdata={trackers}
                    onClose={async (inp) => {
                        if(inp === null) setEditcamp(false);
                        else {
                            let sdate = inp.startDate;
                            if(typeof sdate !== "string")
                                sdate = sdate.toISOString().substr(0, 10);
                            let edate = inp.endDate;
                            if(typeof edate !== "string")
                                edate = edate.toISOString().substr(0, 10);

                            let payload = {
                                id: inp.campaignid,
                                name: inp.campaignName,
                                startdate: sdate,
                                enddate: edate,
                                type: inp.type,
                                trackers: JSON.stringify(inp.intrackers),
                            };
                            await Utils.genericMutation("updateCampaign", payload);
                            await getCompanyName();
                            setEditcamp(false);
                        }
                    }}
                />
                <NewCampaignD2
                    open={newCampDiag2}
                    data={diag2input}
                    onClose={async (datain) => {
                        if(datain === null) {
                            setNewCampDiag2(false);
                        } else {
                            let data = Object.assign({}, datain);
                            Utils.sendProgressBar(true);
                            setSpinner(true)
                            setNewCampDiag2(false);
                            if(typeof data.startDate === "object")
                                data.startDate = data.startDate.toISOString().substr(0, 10);
                            if(typeof data.endDate === "object")
                                data.endDate = data.endDate.toISOString().substr(0, 10);
                            let s = await Utils.genericMutation("createCampaign", {
                                name: data.campaignName,
                                startdate: data.startDate,
                                enddate: data.endDate,
                                type: data.type,
                                trackers: JSON.stringify(data.trackers),
                                groups: companyName.filter((z) => z !== "admin"),
                                pinned: false,
                                active: true,
                                companyCampaignsId: data.companyId,
                            });
                            setSpinner(false)
                            navigate("/campaign?id=" + s.data.createCampaign.id);

                        }
                    }}
                />
                <div className="search-bar-alignment">
                    <SearchBar callBack={(searchValue) => setSearchValue(searchValue)} />
                </div>
            </div>
            <div className="vocalysed-home">
                <div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            style={{
                                textTransform: "none",
                                /*float: "right",*/
                                borderRadius: 20,
                                marginRight: 20,
                                /*marginTop: 16,*/
                                marginBottom: 20,
                                background: colours.primary100,
                                fontFamily: "Poppins",
                            }}
                            variant="contained"
                            onClick={() => setNewCampDiag(true)}
                        >
                            <FeatherIcon
                                icon="folder-plus"
                                style={{ color: colours.white, marginRight: 8 }}
                            />
                            New campaign
                        </Button>
                    </div>
                    <div className="vocalysd-card vocalysd-table">
                        <Table>
                            <TableHead>
                                <TableRow style={{ fontFamily: "Poppins" }}>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Campaign / Entity
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Pin
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Type
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins", textAlign: "center" }}>
                                            Status
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins", textAlign: "center" }}>
                                            Avg Vocalysd score
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Date created
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Edit
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {spinner ? (
                                    <TableRow>
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                        <TableCell>
                                            <CircularProgress style={{ color: colours.primary100 }} />
                                        </TableCell>
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                ) : (
                                    allCampaigns
                                        .sort((a, b) => b.pinned - a.pinned)
                                        .map((z, zidx) => (
                                            <TableRow
                                                key={zidx}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => navigate("/campaign?id=" + z.id)}
                                            >
                                                <TableCell>
                                                    <div
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontFamily: "Poppins",
                                                        }}
                                                    >
                                                        {z.name}
                                                    </div>
                                                    <div style={{ fontFamily: "Poppins" }}>
                                                        {z.company.name}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <PushPinOutlinedIcon
                                                        onClick={async (e) => {
                                                            e.stopPropagation();
                                                            await Utils.genericMutation("updateCampaign", {
                                                                id: z.id,
                                                                pinned: !z.pinned,
                                                            });
                                                            await getCompanyName(true);
                                                        }}
                                                        style={{
                                                            color: z.pinned ? "#E7A500" : "#DEE2F1",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <div style={{ fontFamily: "Poppins" }}>{z.type}</div>
                                                </TableCell>
                                                <TableCell style={{
                                                    textAlign: "center"
                                                }}>
                                                    <div>
                                                        <Chip
                                                            label={z.active ? "Active" : "Inactive"}
                                                            style={chipstyle(z.active)}
                                                        />
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{
                                                    textAlign: "center"
                                                }}>
                                                    <div style={{ fontFamily: "Poppins" }}>
                                                        {z.VShits
                                                            ? (
                                                                JSON.parse(z.VShits).VS /
                                                                JSON.parse(z.VShits).n
                                                            ).toFixed(0)
                                                            : 0}
                                                        %
                                                    </div>
                                                </TableCell>

                                                <TableCell>
                                                    <div
                                                        style={{
                                                            fontFamily: "Poppins",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {format(new Date(z.startdate), 'd/MM/yyyy')}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div>
                                                        <EditOutlinedIcon
                                                            style={{
                                                                color: colours.subduedgray,
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={async (e) => {
                                                                e.stopPropagation();
                                                                setEditcampdata(z);
                                                                setEditcamp(true);
                                                            }}
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </div>

                {true ? null : (
                    <div style={{ width: "20%", background: "#fff", marginLeft: 10 }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                            }}
                        >
                            <div>Filters</div>
                            <Button style={{ textTransform: "none" }}>Reset all</Button>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                            }}
                        >
                            <div>DATE RANGE</div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From"
                                    InputLabelProps={{
                                        style: { color: '#84858a' },
                                    }}
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <DatePicker
                                    label="To"
                                    InputLabelProps={{
                                        style: { color: '#84858a' },
                                    }}
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <div>Limit to</div>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Entity"
                                    InputLabelProps={{
                                        style: { color: '#84858a' },
                                    }}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Campaigns"
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Agents"
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                            <Button style={{ textTransform: "none" }} variant="outlined">
                                Apply
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Campaigns;

function NewCampaignD1({ open, onClose, companydata }) {
    const [companyName, setCompanyName] = React.useState("");
    const [companyId, setCompanyId] = React.useState("");
    const [campaignName, setCampaignName] = React.useState("");
    const [type, setType] = React.useState("");
    let today = new Date(Date.now()).toISOString().substr(0, 10);

    const [startDate, setStartDate] = React.useState(today);
    const [endDate, setEndDate] = React.useState(today);

    async function getCompanyName() {
        setCompanyId(companydata.id);
     
        setCompanyName(companydata.name);
    }

    React.useEffect(() => getCompanyName(), [companydata]);

    return (
        <Dialog
            disableAutoFocus
            onClose={() => onClose(null)}
            onBackdropClick={() => {
                onClose(null);
            }}
            open={open}
            style={{
                padding: 0,

                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 30,
            }}
        >
            <DialogTitle style={{ padding: 0, minWidth: "600px", width: "600px" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 20,

                            fontFamily: "Poppins",
                        }}
                    >
                        <div>New campaign</div>

                        <FeatherIcon icon="x" style={{ color: "#ddd", cursor: "pointer" }} onClick={() => onClose(null)} />
                    </div>
                    <Divider style={{}} />
                </div>
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        jus: "space-between",
                        alignItems: "center",
                        padding: 20,
                        fontFamily: "Poppins",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Avatar
                                style={{
                                    background: colours.purple100,
                                    color: "#fff",
                                    width: 24,
                                    height: 24,
                                    fontSize: 14,
                                    marginRight: 7,
                                }}
                            >
                                1
                            </Avatar>
                            Campaign details
                        </div>
                        <div
                            style={{
                                marginRight: 7,
                                marginLeft: 7,
                                width: 60,
                                height: 0,
                                borderBottom: "1px solid #ddd",
                            }}
                        />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Avatar
                                style={{
                                    background: "#ddd",
                                    color: "#fff",
                                    width: 24,
                                    height: 24,
                                    fontSize: 14,
                                    marginRight: 7,
                                }}
                            >
                                2
                            </Avatar>
                            Add trackers
                        </div>
                    </div>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        style={{ width: "100%", marginTop: 20 }}
                        label="Entity"
                        InputLabelProps={{
                            style: { color: '#84858a' },
                        }}
                        disabled={true}
                        value={companyName ? companyName : ""}
                    />
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        style={{ width: "100%", marginTop: 20 }}
                        label="Campaign name"
                        InputLabelProps={{
                            style: { color: '#84858a' },
                        }}
                        value={campaignName}
                        onChange={(c) => {
                            setCampaignName(c.target.value);
                        }}
                    />

                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        style={{ width: "100%", marginTop: 20 }}
                        label="Type"
                        InputLabelProps={{
                            style: { color: '#84858a' },
                        }}
                        multiline={true}
                        value={type}
                        onChange={(c) => {
                            setType(c.target.value);
                        }}
                    />
                    <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enzaLocale}
                        >
                            <DatePicker
                                label="Start date"
                                InputLabelProps={{
                                    style: { color: '#84858a' },
                                }}
                                value={startDate}
                                onChange={(newValue) => {
                                    setStartDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DatePicker
                                label="End date"
                                InputLabelProps={{
                                    style: { color: '#84858a' },
                                }}
                                value={endDate}
                                onChange={(newValue) => {
                                    setEndDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <Divider style={{}} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 20,
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        style={{
                            textTransform: "none",
                            float: "right",
                            marginRight: 5,
                            borderRadius: 20,
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            background: colours.white,
                            color: colours.primary100,
                            border: "1px solid #ddd",
                            boxShadow: "none",
                        }}
                        variant="contained"
                        onClick={() => onClose(null)}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            textTransform: "none",
                            float: "right",
                            borderRadius: 20,
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            background: colours.primary100,
                        }}
                        variant="contained"
                        onClick={() => {
                            onClose({
                                startDate,
                                endDate,
                                companyName,
                                campaignName,
                                companyId,
                                type,
                            });
                        }}
                    >
                        Next
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

function NewCampaignD2({ open, onClose, data }) {
    const [trackers, setTrackers] = React.useState([]);
    const [intrackers, setInTrackers] = React.useState([]);

    function setDefaultTrackers(trackers) {
        var defaultTrackers = [];
        if(trackers && trackers.length > 0) {
            for(var i = 0; i < trackers.length; i++) {
                if(trackers[i].name.includes("-detect")) {
                    defaultTrackers.push(trackers[i]);
                }
            }

            setInTrackers(defaultTrackers);
        }
    }

    React.useEffect(() => {
        if(data) {
            //sortTrackers(data.trackers);
            setDefaultTrackers(data.trackers);
            setTrackers(data.trackers);
        }
    }, [data]);

    return (
        <Dialog
            disableAutoFocus
            onClose={() => onClose()}
            onBackdropClick={() => onClose()}
            open={open}
            style={{
                padding: 0,

                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 30,
            }}
        >
            <DialogTitle style={{ padding: 0, minWidth: "600px", width: "600px" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 20,

                            fontFamily: "Poppins",
                        }}
                    >
                        <div>New campaign</div>

                        <FeatherIcon icon="x" style={{ color: "#ddd", cursor: "pointer" }} onClick={() => onClose(null)} />
                    </div>
                    <Divider style={{}} />
                </div>
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        jus: "space-between",
                        alignItems: "center",
                        padding: 20,
                        fontFamily: "Poppins",
                    }}
                >
                    <div
                        style={{
                            display: "flex",

                            flexDirection: "column",
                            jus: "space-between",
                            alignItems: "center",
                            padding: 20,
                            fontFamily: "Poppins",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Avatar
                                    style={{
                                        background: colours.purple100,
                                        color: "#fff",
                                        width: 24,
                                        height: 24,
                                        fontSize: 14,
                                        marginRight: 7,
                                    }}
                                >
                                    1
                                </Avatar>
                                Campaign details
                            </div>
                            <div
                                style={{
                                    marginRight: 7,
                                    marginLeft: 7,
                                    width: 60,
                                    height: 0,
                                    borderBottom: "1px solid #ddd",
                                }}
                            />
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Avatar
                                    style={{
                                        background: colours.purple100,
                                        color: "#fff",
                                        width: 24,
                                        height: 24,
                                        fontSize: 14,
                                        marginRight: 7,
                                    }}
                                >
                                    2
                                </Avatar>
                                Add trackers
                            </div>
                        </div>
                        <FormControl fullWidth style={{ marginTop: 30, width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">
                                Add tracker sets
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={intrackers}
                                onChange={(z) => {
                                    var defaultTrackers = [];
                                    var selecteds = z.target.value;
                                    if(data.trackers && data.trackers.length > 0) {
                                        for(var i = 0; i < data.trackers.length; i++) {
                                            if(data.trackers[i].name.includes("-detect")) {

                                                if(selecteds.some(item => data.trackers[i].name === item.name)) {

                                                } else {

                                                    selecteds.push(data.trackers[i]);
                                                }
                                            }
                                        }

                                        //setInTrackers(defaultTrackers);
                                    }
                                    setInTrackers(selecteds);
                                }}
                                label="Add tracker sets"
                                inputlabelprops={{
                                    style: { color: '#84858a' },
                                }}
                                multiple
                                minrows={3}
                                multiline
                                renderValue={(selected) => {
                                    return selected.map((j) => <div>{j.name}</div>);
                                }}
                                MenuProps={{ width: "100%" }}
                            >
                                {trackers.map((z, zid) => (
                                    <MenuItem value={z} key={zid}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Checkbox
                                                checked={
                                                    intrackers.filter((l) => l.name === z.name).length > 0
                                                }
                                            />
                                            <ListItemText primary={z.name} />
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <Divider style={{}} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 20,
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        style={{
                            textTransform: "none",
                            float: "right",
                            marginRight: 5,
                            borderRadius: 20,
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            background: colours.white,
                            color: colours.primary100,
                            border: "1px solid #ddd",
                            boxShadow: "none",
                        }}
                        variant="contained"
                        onClick={() => onClose(null)}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            textTransform: "none",
                            float: "right",
                            borderRadius: 20,
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            background: colours.primary100,
                        }}
                        variant="contained"
                        onClick={() => {
                            data.trackers = intrackers;
                            onClose(data);
                        }}
                    >
                        Next
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

function NewCampaignD3({ open, onClose, companydata, intrackersdata }) {
    const [companyName, setCompanyName] = React.useState("");
    const [companyId, setCompanyId] = React.useState("");
    const [campaignName, setCampaignName] = React.useState("");
    const [type, setType] = React.useState("");
    let today = new Date(Date.now()).toISOString().substr(0, 10);
    const [trackers, setTrackers] = React.useState([]);
    const [intrackers, setInTrackers] = React.useState([]);

    const [startDate, setStartDate] = React.useState(today);
    const [endDate, setEndDate] = React.useState(today);

    async function getCompanyName() {
        setCampaignName(companydata.name);
        setType(companydata.type);
        setStartDate(companydata.startdate);
        setEndDate(companydata.enddate);
        if(companydata.trackers) {
            let trk = JSON.parse(companydata.trackers);
            trk = trk.filter(
                (value, index, self) =>
                    index === self.findIndex((t) => t.name === value.name)
            );
            setInTrackers(trk);
        }
        setTrackers(intrackersdata);
    }

    React.useEffect(() => getCompanyName(), [companydata]);

    return (
        <Dialog
            disableAutoFocus
            onClose={() => onClose(null)}
            onBackdropClick={() => {
                onClose(null);
            }}
            open={open}
            style={{
                padding: 0,

                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 30,
            }}
        >
            <DialogTitle style={{ padding: 0, minWidth: "600px", width: "600px" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 20,

                            fontFamily: "Poppins",
                        }}
                    >
                        <div>Edit campaign</div>

                        <FeatherIcon icon="x" style={{ color: "#ddd", cursor: "pointer" }} onClick={() => onClose(null)} />
                    </div>
                    <Divider style={{}} />
                </div>
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        jus: "space-between",
                        alignItems: "center",
                        padding: 20,
                        fontFamily: "Poppins",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    ></div>

                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        style={{ width: "100%", marginTop: 20 }}
                        label="Campaign name"
                        value={campaignName}
                        onChange={(c) => {
                            setCampaignName(c.target.value);
                        }}
                    />

                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        style={{ width: "100%", marginTop: 20 }}
                        label="Type"
                        multiline={true}
                        value={type}
                        onChange={(c) => {
                            setType(c.target.value);
                        }}
                    />
                    <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enzaLocale}
                        >
                            <DatePicker
                                label="Start date"
                                value={startDate}
                                onChange={(newValue) => {
                                    setStartDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DatePicker
                                label="End date"
                                value={endDate}
                                onChange={(newValue) => {
                                    setEndDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    {true ? null : (
                        <FormControl fullWidth style={{ marginTop: 30, width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">
                                Add tracker sets
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={intrackers}
                                onChange={(z) => {
                                    let trk = Object.assign([], z.target.value);
                                    trk = trk.filter(
                                        (value, index, self) =>
                                            index === self.findIndex((t) => t.name === value.name)
                                    );
                                    setInTrackers(trk);
                                }}
                                onSelect={(e) => {
                                    console.log(e);
                                }}
                                label="Add tracker sets"
                                InputLabelProps={{
                                    style: { color: '#84858a' },
                                }}
                                multiple
                                minrows={3}
                                multiline
                                renderValue={(selected) => {

                                    return selected.map((j, ji) => <div key={ji}>{j.name}</div>);
                                }}
                                MenuProps={{ width: "100%" }}
                            >
                                {trackers.map((z, zid) => (
                                    <MenuItem value={z} key={zid}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Checkbox
                                                onClick={(e) => {
                                                    if(e.target.checked === false) {
                                                        let trk = Object.assign([], intrackers);
                                                        trk = trk.filter((w) => w.name !== z.name);
                                                        setInTrackers(trk);
                                                    }
                                                }}
                                                checked={
                                                    intrackers.filter((l) => l.name === z.name).length > 0
                                                }
                                            />
                                            <ListItemText primary={z.name} />
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </div>
                <Divider style={{}} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 20,
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        style={{
                            textTransform: "none",
                            float: "right",
                            marginRight: 5,
                            borderRadius: 20,
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            background: colours.white,
                            color: colours.primary100,
                            border: "1px solid #ddd",
                            boxShadow: "none",
                        }}
                        variant="contained"
                        onClick={() => onClose(null)}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            textTransform: "none",
                            float: "right",
                            borderRadius: 20,
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            background: colours.primary100,
                        }}
                        variant="contained"
                        onClick={() => {
                            onClose({
                                startDate,
                                endDate,

                                campaignName,
                                campaignid: companydata.id,
                                type,
                                intrackers,
                            });
                        }}
                    >
                        Save
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
